import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { CalendarService } from "../../shared/services/calendar.service";
import { PrenotazioneService } from "../../services/prenotazione.service";
import { first } from "rxjs";

@Component({
  selector: "app-calendar",
  templateUrl: "./calendar.component.html",
  styleUrls: ["./calendar.component.css"],
})
export class CalendarComponent implements OnInit {
  @Input() stanzaId!: number;

  dateClicked: { weekNumber: number; isSelected: boolean[] } = {
    weekNumber: 0,
    isSelected: new Array(7).fill(false),
  };
  hoursAvailable = [
    { hour: "10:00 - 11:00", isAvailable: true },
    { hour: "11:30 - 12:30", isAvailable: true },
    { hour: "16:00 - 17:00", isAvailable: true },
    { hour: "17:30 - 18:30", isAvailable: true },
    { hour: "19:00 - 20:00", isAvailable: true },
    { hour: "20:30 - 21:30", isAvailable: true },
    { hour: "22:00 - 23:00", isAvailable: true },
  ];
  year: number;
  mounth: number;
  selectedDay: number | undefined;
  selectedMounth: number;
  selectedYear: number;
  currentDay: number;
  currentMounth: number;
  currentYear: number;
  firstWeek: number[];
  secondWeek: number[];
  thirdWeek: number[];
  fourthWeek: number[];
  fifthWeek: number[];
  sixthWeek: number[];
  daysOfWeek = [
    "Lunedì",
    "Martedì",
    "Mercoledì",
    "Giovedì",
    "Venerdì",
    "Sabato",
    "Domenica",
  ];
  monthNames = [
    "Gennaio",
    "Febbraio",
    "Marzo",
    "Aprile",
    "Maggio",
    "Giugno",
    "Luglio",
    "Agosto",
    "Settembre",
    "Ottobre",
    "Novembre",
    "Dicembre",
  ];

  constructor(
    public calendarService: CalendarService,
    private _prenotazioneService: PrenotazioneService,
    private _cd: ChangeDetectorRef,
  ) {
    this.year = new Date().getFullYear();
    this.mounth = new Date().getMonth();
    this.selectedMounth = new Date().getMonth();
    this.selectedYear = new Date().getFullYear();
    this.currentMounth = new Date().getMonth();
    this.currentYear = new Date().getFullYear();
    this.currentDay = new Date().getDate();
    this.firstWeek = this.getCalendartFirstWeek(this.year, this.mounth);
    this.secondWeek = this.getCalendarSecondWeek(this.year, this.mounth);
    this.thirdWeek = this.getCalendarThirdWeek(this.year, this.mounth);
    this.fourthWeek = this.getCalendarFourthWeek(this.year, this.mounth);
    this.fifthWeek = this.getCalendarFifthWeek(this.year, this.mounth);
    this.sixthWeek = this.getCalendarSixthWeek(this.year, this.mounth);
  }

  ngOnInit(): void {
    this.getPrenotazioni();
  }

  getMonthName(monthNumber: number): string {
    if (monthNumber >= 0 && monthNumber <= 11) {
      return this.monthNames[monthNumber];
    } else {
      return "Mese non valido";
    }
  }

  getPrenotazioni() {
    this.resetHoursAvailable();
    this._prenotazioneService
      .getPrenotazioni(this.stanzaId, this.dataPrenotazioneFormatter())
      .pipe(first())
      .subscribe((prenotazioni: any) => {
        for (const prenotazione of prenotazioni) {
          const ele = this.hoursAvailable.find((ele) =>
            ele.hour.includes(prenotazione.orario),
          );
          if (ele) {
            ele.isAvailable = false;
          }
        }
      });
  }

  getMounthDays(year: number, mounth: number): number {
    const lastDayOfMonth = new Date(year, mounth + 1, 0);
    return lastDayOfMonth.getDate();
  }

  getCalendartFirstWeek(year: number, mounth: number) {
    const firstWeek: number[] = [];
    const previousMounthDaysNumber = this.getMounthDays(
      new Date(year, mounth - 1).getFullYear(),
      new Date(year, mounth - 1).getMonth(),
    );
    let firstDayOfMounth =
      new Date(year, mounth, 1).getDay() === 0
        ? 7
        : new Date(year, mounth, 1).getDay();
    let firstDayOfCalendar = previousMounthDaysNumber - firstDayOfMounth + 2;
    for (let i = 1; i < 8; i++) {
      if (firstDayOfMounth !== 1) {
        if (firstDayOfCalendar <= previousMounthDaysNumber) {
          firstWeek.push(firstDayOfCalendar);
        }
      } else {
        firstDayOfCalendar = 1;
        firstDayOfMounth = 0;
        firstWeek.push(firstDayOfCalendar);
      }
      if (firstDayOfCalendar <= previousMounthDaysNumber) {
        firstDayOfCalendar++;
      } else {
        firstDayOfCalendar = 1;
        firstWeek.push(firstDayOfCalendar);
        firstDayOfCalendar++;
      }
    }
    return firstWeek;
  }

  getCalendarSecondWeek(year: number, mounth: number) {
    const secondWeek: number[] = [];
    const currentMounthDaysNumber = this.getMounthDays(
      new Date(year, mounth).getFullYear(),
      new Date(year, mounth).getMonth(),
    );
    const previousWeekLastDay = this.firstWeek[this.firstWeek.length - 1];
    for (let i = 1; i < 8; i++) {
      if (previousWeekLastDay + i <= currentMounthDaysNumber) {
        secondWeek.push(previousWeekLastDay + i);
      }
    }
    return secondWeek;
  }

  getCalendarThirdWeek(year: number, mounth: number) {
    const thirdWeek: number[] = [];
    const previousWeekLastDay = this.secondWeek[this.secondWeek.length - 1];
    const currentMounthDaysNumber = this.getMounthDays(
      new Date(year, mounth).getFullYear(),
      new Date(year, mounth).getMonth(),
    );
    for (let i = 1; i < 8; i++) {
      if (previousWeekLastDay + i <= currentMounthDaysNumber) {
        thirdWeek.push(previousWeekLastDay + i);
      }
    }
    return thirdWeek;
  }

  getCalendarFourthWeek(year: number, mounth: number) {
    const fourthWeek: number[] = [];
    const previousWeekLastDay = this.thirdWeek[this.thirdWeek.length - 1];
    const currentMounthDaysNumber = this.getMounthDays(
      new Date(year, mounth).getFullYear(),
      new Date(year, mounth).getMonth(),
    );
    for (let i = 1; i < 8; i++) {
      if (previousWeekLastDay + i <= currentMounthDaysNumber) {
        fourthWeek.push(previousWeekLastDay + i);
      }
    }
    return fourthWeek;
  }

  getCalendarFifthWeek(year: number, mounth: number) {
    const fourthWeek: number[] = [];
    let followingMounthDay = 0;
    let previousWeekLastDay = this.fourthWeek[this.fourthWeek.length - 1];
    const currentMounthDaysNumber = this.getMounthDays(
      new Date(year, mounth).getFullYear(),
      new Date(year, mounth).getMonth(),
    );
    for (let i = 1; i < 8; i++) {
      if (previousWeekLastDay + i <= currentMounthDaysNumber) {
        fourthWeek.push(previousWeekLastDay + i);
      } else {
        followingMounthDay += 1;
        fourthWeek.push(followingMounthDay);
      }
    }
    return fourthWeek;
  }
  getCalendarSixthWeek(year: number, mounth: number) {
    const fourthWeek: number[] = [];
    let followingMounthDay = 0;
    let previousWeekLastDay = this.fifthWeek[this.fifthWeek.length - 1];
    const maxPreviousWeekLastDay = Math.max(...this.fifthWeek);
    const currentMounthDaysNumber = this.getMounthDays(
      new Date(year, mounth).getFullYear(),
      new Date(year, mounth).getMonth(),
    );
    if (maxPreviousWeekLastDay < currentMounthDaysNumber) {
      for (let i = 1; i < 8; i++) {
        if (previousWeekLastDay + i <= currentMounthDaysNumber) {
          fourthWeek.push(previousWeekLastDay + i);
        } else {
          followingMounthDay += 1;
          fourthWeek.push(followingMounthDay);
        }
      }
    }
    return fourthWeek;
  }

  getCalendarWeeks(year: number, mounth: number) {
    this.firstWeek = this.getCalendartFirstWeek(year, mounth);
    this.secondWeek = this.getCalendarSecondWeek(year, mounth);
    this.thirdWeek = this.getCalendarThirdWeek(year, mounth);
    this.fourthWeek = this.getCalendarFourthWeek(year, mounth);
    this.fifthWeek = this.getCalendarFifthWeek(year, mounth);
    this.sixthWeek = this.getCalendarSixthWeek(year, mounth);
  }

  onClickMesePrecedente() {
    if (this.mounth - 1 < 0) {
      this.mounth = 11;
      this.year -= 1;
    } else {
      this.mounth -= 1;
    }
    this.selectedYear = this.year;
    this.selectedDay = undefined;
    this.selectedMounth = this.mounth;
    const isSelected = new Array(7).fill(false);
    this.dateClicked = { weekNumber: 0, isSelected };
    this.getCalendarWeeks(this.year, this.mounth);
  }

  onClickMeseSuccessivo() {
    if (this.mounth + 1 > 11) {
      this.mounth = 0;
      this.year += 1;
    } else {
      this.mounth += 1;
    }
    this.selectedYear = this.year;
    this.selectedDay = undefined;
    this.selectedMounth = this.mounth;
    const isSelected = new Array(7).fill(false);
    this.dateClicked = { weekNumber: 0, isSelected };
    this.getCalendarWeeks(this.year, this.mounth);
  }

  onDateClicked(weekNumber: number, index: number, day: number) {
    let isSelected;
    if (
      this.selectedDay === day &&
      this.selectedMounth === this.mounth &&
      this.selectedYear === this.year
    ) {
      this.selectedDay = undefined;
      isSelected = new Array(7).fill(false);
      this.dateClicked = { weekNumber: weekNumber, isSelected: isSelected };
    } else {
      this.selectedDay = day;
      this.selectedMounth = this.mounth;
      this.selectedYear = this.year;
      isSelected = new Array(7).fill(false);
      isSelected[index] = true;
      this.dateClicked = { weekNumber: weekNumber, isSelected: isSelected };
    }
    this.getPrenotazioni();
  }

  prenota(hour: string) {
    const orario = hour.substring(0, 5);
    this._prenotazioneService
      .makePrenotazione(this.stanzaId, this.dataPrenotazioneFormatter(), orario)
      .pipe(first())
      .subscribe((response: any) => {
        setTimeout(() => {
          const ele = this.hoursAvailable.find((ele) =>
            ele.hour.includes(response.orario),
          );
          if (ele) {
            ele.isAvailable = false;
          }
        });
        this._cd.detectChanges();
      });
  }

  disableDayOfWeek(
    weekConisdered: number[],
    weekNumber: number,
    index: number,
    day: number,
    mounth: number,
    year: number,
  ): boolean {
    if (
      year < this.currentYear ||
      (year === this.currentYear && mounth < this.currentMounth) ||
      (year === this.currentYear &&
        mounth === this.currentMounth &&
        day < this.currentDay)
    ) {
      return true;
    }
    if (weekNumber === 1) {
      for (let i = index; i < weekConisdered.length; i++) {
        if (weekConisdered[index] > weekConisdered[i]) {
          return true;
        }
      }
    }
    if ([5, 6].includes(weekNumber)) {
      if (weekConisdered[index] < Math.max(...weekConisdered) - 7) {
        return true;
      }
    }
    return false;
  }

  private dataPrenotazioneFormatter() {
    const giorno = this.selectedDay ? this.selectedDay : +new Date().getDate();
    const day: string = giorno > 9 ? giorno.toString() : `0${giorno}`;
    const mounth =
      this.selectedMounth > 9
        ? this.selectedMounth.toString()
        : `0${this.selectedMounth}`;
    return `${this.selectedYear}-${mounth}-${day}`;
  }

  resetHoursAvailable() {
    for (const ele of this.hoursAvailable) {
      ele.isAvailable = true;
    }
    this._cd.detectChanges();
  }
}
