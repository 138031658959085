<div class="container">
  <div class="left-side">
    <div class="title">
      <div class="vertical-bar"></div>
      <div>Chi può giocare</div>
    </div>
    <div class="content">
      <div>
        <div class="icon-famiglia"></div>
        <section>FAMIGLIE E AMICI</section>
      </div>
      <div>
        <div class="icon-lavoro"></div>
        <section>COLLEGHI DI TEAM</section>
      </div>
      <div>
        <div class="icon-puzzle"></div>
        <section>AMANTI DEGLI ENIGMI</section>
      </div>
    </div>
  </div>
  <div class="right-side"></div>
</div>
