import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};
@Injectable({
  providedIn: "root",
})
export class StanzeService {
  constructor(private http: HttpClient) {}

  getStanze() {
    return this.http.get("/api/stanze", httpOptions);
  }
}
