<div class="container" #stanzaElementRef>
  <div
    class="content"
    id="{{ stanzaId }}"
    [@fadeInOut]="isVisible ? 'visible' : 'hidden'"
    (mouseenter)="onHoverEnter()"
    (mouseleave)="onHoverLeave()"
  >
    <img
      class="image"
      [ngStyle]="
        isHovered && !isClicked ? { cursor: 'pointer' } : { cursor: 'auto' }
      "
      [src]="'../../../assets/img/' + imgStanza"
    />
    <div *ngIf="isHovered && !isClicked" class="gradiente-sovrapposto"></div>
    <div class="header" *ngIf="isHovered && !isClicked">
      <div class="locker">
        <div
          [ngStyle]="difficulty >= 1 ? { color: 'red' } : { color: '#7a7a7a' }"
          class="icon-lucchetto-chiuso"
        ></div>
        <div
          [ngStyle]="difficulty >= 2 ? { color: 'red' } : { color: '#7a7a7a' }"
          class="icon-lucchetto-chiuso"
        ></div>
        <div
          [ngStyle]="difficulty >= 3 ? { color: 'red' } : { color: '#7a7a7a' }"
          class="icon-lucchetto-chiuso"
        ></div>
        <div
          [ngStyle]="difficulty >= 4 ? { color: 'red' } : { color: '#7a7a7a' }"
          class="icon-lucchetto-chiuso"
        ></div>
        <div
          [ngStyle]="difficulty >= 5 ? { color: 'red' } : { color: '#7a7a7a' }"
          class="icon-lucchetto-chiuso"
        ></div>
      </div>
    </div>
    <div class="title">{{ title }}</div>
    <div class="footer" *ngIf="isHovered && !isClicked">
      <div class="icon-users" style="color: red">
        <span style="color: white"> {{ players }}</span>
      </div>
    </div>
  </div>
  <div *ngIf="isLeStanzaPage && isClicked" class="description-room">
    <div class="description-header">
      <div class="description-difficulty">
        <div class="locker">
          <div
            [ngStyle]="
              difficulty >= 1 ? { color: 'red' } : { color: '#7a7a7a' }
            "
            class="icon-lucchetto-chiuso"
          ></div>
          <div
            [ngStyle]="
              difficulty >= 2 ? { color: 'red' } : { color: '#7a7a7a' }
            "
            class="icon-lucchetto-chiuso"
          ></div>
          <div
            [ngStyle]="
              difficulty >= 3 ? { color: 'red' } : { color: '#7a7a7a' }
            "
            class="icon-lucchetto-chiuso"
          ></div>
          <div
            [ngStyle]="
              difficulty >= 4 ? { color: 'red' } : { color: '#7a7a7a' }
            "
            class="icon-lucchetto-chiuso"
          ></div>
          <div
            [ngStyle]="
              difficulty >= 5 ? { color: 'red' } : { color: '#7a7a7a' }
            "
            class="icon-lucchetto-chiuso"
          ></div>
        </div>
        <div>Difficoltà</div>
      </div>
      <div class="description-time">
        <div style="color: #7a7a7a">{{ duration }}</div>
        <div>Durata</div>
      </div>
      <div class="description-player">
        <div style="color: #7a7a7a">{{ players }}</div>
        <div>Giocatori</div>
      </div>
    </div>
    <div class="description" [innerHTML]="description"></div>
    <div class="description-footer">
      <button
        *ngIf="isButtonVisibled"
        mat-raised-button
        (click)="onPrenotaClick()"
      >
        Prenota ora!
      </button>
      <app-calendar
        *ngIf="calendarService.mostraCalendar$ | async"
        [stanzaId]="+stanzaId.charAt(stanzaId.length - 1)"
      >
      </app-calendar>
    </div>
  </div>
</div>
