<div class="container">
  <h1>Informativa sui Cookie</h1>

  <article class="testo">
    <p><em>(versione 1.0 del 25/05/2018)</em></p>

    <p>
      Gentile Visitatore/Visitatrice (di seguito, "<strong>Tu</strong>" o
      l'"<strong>Utente</strong>"),
    </p>

    <p>
      Questo sito Internet www.fugaimpossibile.it (di seguito, il
      "<strong>Sito</strong>") è di proprietà della Blitz APS, con sede
      legale in via Della Grada 8/a-b-c, Codice
      Fiscale<small>&nbsp;91438110370&nbsp;</small>(di seguito,
      "<strong>Noi</strong>" o "l'Associazione").
    </p>

    <p>
      Poichè; sul nostro Sito sono utilizzati dei c.d. cookie per diverse
      finalità, con questa informativa vogliamo spiegarti cosa sono i cookie e
      come vengono utilizzati sul Sito.
    </p>

    <p>
      Alcuni cookie (c.d. cookie propri o di prima parte) sono veicolati e
      controllati direttamente da noi. Attraverso questi cookie, L'Associazione
      raccoglie e tratta alcuni dati personali che ti riguardano. In questa
      informativa, ai sensi dell&rsquo;art. 13 del Regolamento UE 2016/679 del
      27 aprile 2016
      <em
        >relativo alla protezione delle persone fisiche con riguardo al
        trattamento dei dati personali</em
      >
      (di seguito, &ldquo;<strong>Regolamento</strong>&rdquo;), ti spieghiamo
      come raccogliamo questi dati, per quali finalità e quali sono i tuoi
      diritti.
    </p>

    <p>
      Altri cookie (c.d. <em>cookie di terze parti</em>), sono, invece,
      veicolati e controllati da soggetti terzi. Su questa informativa troverai
      dei link alle informative pubblicate da questi soggetti terzi, che ti
      preghiamo di leggere con attenzione.
    </p>

    <p>
      Se non hai familiarità con i cookie, ti preghiamo di leggere attentamente
      questa informativa e l'informativa privacy generale del Sito, così che tu
      possa essere consapevole delle tue scelte.
    </p>

    <p>
      L'Associazione tiene nella massima considerazione il diritto alla privacy
      e alla protezione dei dati personali dei propri Utenti. Per qualsiasi
      informazione relativa alla presente Informativa Cookie, gli Utenti possono
      contattare L'Associazione in qualsiasi momento, utilizzando i seguenti
      metodi:
    </p>

    <ul class="lista_puntata">
      <li>
        <p>
          Inviando una lettera raccomandata con ricevuta di ritorno alla sede
          legale del Titolare, Blitz APS, via Della Grada 8/a-b-c, 40122
          Bologna;
        </p>
      </li>
      <li>
        <p>
          Inviando una e-mail a
          <a href="mailto:bologna@fugaimpossibile.it">bologna@fugaimpossibile.it</a>;
        </p>
      </li>
    </ul>

    <p>
      L'Associazione non ha individuato la figura del Responsabile della
      protezione dei dati (RPD o DPO), in quanto non è soggetto
      all&rsquo;obbligo di designazione previsto dall&rsquo;art. 37 del
      Regolamento.
    </p>

    <ol class="lista_alfabetica_maiuscola">
      <li>
        <h3>Cosa sono i <em>cookie</em> ed a cosa servono</h3>

        <p>
          I cookie sono piccoli file di testo che i siti visitati dall'utente
          inviano direttamente al suo terminale (solitamente al browser), dove
          vengono memorizzati per essere poi ritrasmessi agli stessi siti alla
          successiva visita del medesimo utente (c.d.
          <strong>cookie propri di prima parte</strong>). Nel corso della
          navigazione su un sito, l'utente pu&ograve; ricevere sul suo terminale
          anche cookie di siti o di web server diversi (c.d.
          <strong>cookie di terze parti</strong>); ci&ograve; accade perchè; sul
          sito web visitato possono essere presenti elementi come, ad esempio,
          immagini, mappe, suoni, specifici link a pagine web di altri domini
          che risiedono su server diversi da quello sul quale si trova la pagina
          richiesta. In altre parole, sono quei cookie che vengono impostati da
          un sito web diverso da quello che si sta attualmente visitando.
        </p>

        <p>
          I cookie possono avere una durata limitata ad una singola sessione di
          navigazione sul browser (c.d. <strong>cookie di sessione</strong>), ed
          in tal caso si disattivano automaticamente al momento della chiusura
          del browser da parte dell'utente; oppure possono avere una scadenza
          predeterminata e, in tal caso, rimarranno memorizzati ed attivi nel
          tuo hard disk fino a tale data di scadenza, continuando a raccogliere
          informazioni nel corso di differenti sessioni di navigazione sul
          browser (c.d. <strong>cookie permanenti</strong>).
        </p>

        <p>
          I cookie sono utilizzati per diverse funzioni. Alcuni sono necessari
          per consentirti di navigare sul Sito e usufruire delle sue
          funzionalità (c.d. <strong>cookie tecnici</strong>). Altri sono
          utilizzati per ottenere informazioni statistiche, in forma aggregata o
          non, sul numero degli utenti che accedono al Sito e su come il Sito
          viene utilizzato (c.d.
          <strong>cookie di monitoraggio o analytics</strong>). Altri, infine,
          sono utilizzati per tracciare un tuo profilo di consumatore e farti
          visualizzare annunci pubblicitari sul Sito che possano essere per te
          interessanti, in quanto coerenti con i tuoi gusti e le tue abitudini
          di consumo (c.d. <strong>cookie di profilazione</strong>).
        </p>

        <p>
          Per conoscere più in dettaglio queste diverse categorie di cookie
          continua a leggere questa informativa. Scopri come funzionano ed a
          cosa servono, e scegli liberamente se acconsentire al loro utilizzo o
          impedirlo.
        </p>
      </li>
      <li>
        <h3>I cookie tecnici</h3>

        <p>
          <strong><em>Questo sito non utilizza cookie tecnici.</em></strong>
        </p>
      </li>
      <li id="paragrafo_c">
        <h3>I cookie di monitoraggio o &ldquo;analytics&rdquo;</h3>

        <p>
          I cookie analytics sono utilizzati sul Sito per raccogliere
          informazioni statistiche, in forma aggregata o non, sul numero degli
          utenti che accedono al Sito e su come questi visitano il Sito stesso.
        </p>

        <p>
          I cookie analytics di questo Sito sono cookie di terze parti, poichè;
          non vengono direttamente veicolati da noi ma da soggetti terzi. Non
          sono, quindi, installati direttamente dalL'Associazione ma da soggetti
          terzi.
        </p>

        <p>
          I seguenti cookie analytics di terza parte sono installati sul Sito
          senza il tuo consenso preventivo, perchè; essi sono meno invasisi in
          quanto anonimizzati, non potendo le terze parti accedere a dati
          disaggregati di analytics a livello di indirizzo IP (in altre parole,
          utilizzando questi cookie, i soggetti terzi non possono risalire alla
          tua identità):
        </p>

        <table
          border="0"
          cellpadding="0"
          cellspacing="0"
          id="tab_cookie"
          width="100%"
        >
          <tbody>
            <tr>
              <th align="left" valign="top">Nome del Cookie</th>
              <th align="left" valign="top">Categoria del cookie</th>
              <th align="left" valign="top">
                Link all'informativa / Istruzioni sull'opt-out
              </th>
            </tr>
            <tr>
              <td align="left" valign="top">Google Analytics</td>
              <td align="left" valign="top">
                - cookie analytics di terza parte<br />
                - permanente
              </td>
              <td align="left" valign="top">
                <a
                  href="https://policies.google.com/privacy?hl=it"
                  target="_blank"
                  >https://policies.google.com/privacy?hl=it</a
                >
              </td>
            </tr>
          </tbody>
        </table>

        <p>
          Altri cookie analytics di terza parte sono, invece, installati sul
          Sito solo con il tuo previo consenso preventivo, perchè; essi non sono
          anonimizzati, potendo le terze parti accedere a dati disaggregati di
          analytics a livello di indirizzo IP (in altre parole, utilizzando
          questi cookie, i soggetti terzi potrebbero teoricamente risalire alla
          tua identità attraverso l'indirizzo IP)
        </p>

        <p>
          <strong
            ><em>Questo sito non utilizza cookies non anonimizzati</em></strong
          >
        </p>
      </li>
      <li id="paragrafo_d">
        <h3>I cookie di profilazione</h3>

        <p>
          I cookie di profilazione sono utilizzati per creare un tuo profilo di
          utente, basato sulle preferenze ed i gusti da te manifestati durante
          la navigazione su Internet, e farti visualizzare messaggi pubblicitari
          coerenti con il tuo profilo. In questo modo i messaggi pubblicitari
          che visualizzerai sul nostro Sito potranno essere per te maggiormente
          interessanti.
        </p>

        <p>
          Come previsto dalla normativa vigente in materia di privacy, per
          l'installazione di tali cookie è richiesto il tuo preventivo consenso.
          Per questo motivo, quando accedi al Sito viene visualizzato in
          evidenza un apposito banner, che ti informa che (i) sul Sito sono
          utilizzati dei cookie di profilazione e che (ii) chiudendo il banner,
          scorrendo l'<em>home page</em> o cliccando qualunque elemento
          dell'home page al di fuori del banner, si acconsente all'uso dei
          cookie. Qualora tu dovessi esprimere il tuo consenso all'installazione
          dei cookie con questa modalità, terremo traccia del tuo consenso
          attraverso un apposito cookie tecnico. In questo modo, eviteremo di
          farti visualizzare il banner sui cookie nel corso delle tue successive
          visite al Sito. Ti preghiamo di considerare che, qualora tu dovessi
          eliminare questo cookie tecnico dal tuo browser con le modalità di cui
          al successivo
          <a
            href="#"
            onclick="Scroll_a_elemento('paragrafo_e_1'); return false;"
            >paragrafo E, n. 1</a
          >, la traccia del tuo consenso verrebbe persa e, pertanto, nel corso
          della tua successiva visita al Sito il banner sui cookie sarà
          visualizzato nuovamente.
        </p>

        <p>
          Naturalmente sei libero di bloccare l'installazione dei cookie di
          profilazione in qualsiasi momento, senza che la possibilità di
          visitare il Sito e fruire dei suoi contenuti sia in qualsiasi modo
          compromessa. Se decidi di disattivare la pubblicità comportamentale
          non significa che non riceverai più pubblicità sul Sito. Tuttavia, i
          banner che visualizzerai sul Sito potrebbero non rispecchiare i tuoi
          interessi o le tue preferenze sul browser che stai attualmente
          utilizzando.
        </p>

        <p>
          Alcuni dei cookie di profilazione sono installati direttamente
          dalL'Associazione (cookie di profilazione di prima parte). Questi
          cookie sono permanenti ed hanno una durata massima di 12 mesi. Ecco di
          seguito l'elenco dei cookie di profilazione di prima parte utilizzati
          sul Sito, con l'indicazione delle modalità attraverso le quali puoi
          opporti alla loro installazione:
        </p>

        <p>
          <strong
            ><em
              >Questo sito non utilizza cookies di profilazione di prima
              parte</em
            ></strong
          >
        </p>

        <p>
          La maggior parte dei cookie di profilazione sono, invece, installati
          da soggetti terzi, che agiscono in qualità di autonomi titolari del
          trattamento (cookie di terze parti). Ecco un elenco dei cookie di
          profilazione di terze parti attualmente utilizzati sul Sito, con
          l'indicazione del link alle pagine informative create dai loro
          sviluppatori:
        </p>

        <table
          border="0"
          cellpadding="0"
          cellspacing="0"
          id="tab_cookie"
          width="100%"
        >
          <tbody>
            <tr>
              <th align="left" valign="top">Nome del Cookie</th>
              <th align="left" valign="top">Categoria del cookie</th>
              <th align="left" valign="top">Link all'informativa</th>
            </tr>
            <tr>
              <td align="left" valign="top">Google Maps</td>
              <td align="left" valign="top">
                - cookie di profilazione di terza parte<br />
                - permanente
              </td>
              <td align="left" valign="top">
                <a
                  href="https://policies.google.com/privacy?hl=it"
                  target="_blank"
                  >https://policies.google.com/privacy?hl=it</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </li>
      <li>
        <h3>Come gestire i cookie ed opporti al loro utilizzo</h3>

        <p>
          Ci sono diverse opzioni per gestire, disabilitare e cancellare i
          cookie.
        </p>

        <ol class="lista_numerata">
          <li id="paragrafo_e_1">
            <p>
              <strong class="sottolineato"
                >Cambia le impostazioni del tuo browser</strong
              >
            </p>

            <p>
              Segui le istruzioni fornite dal produttore del browser che
              utilizzi per scoprire come gestire, disabilitare o cancellare
              tutti i cookie (tecnici, analytics e di profilazione):
            </p>

            <ul class="lista_puntata">
              <li>
                <p>
                  <strong>Explorer</strong>:
                  <a
                    href="https://support.microsoft.com/en-us/kb/196955/it"
                    target="_blank"
                    >https://support.microsoft.com/en-us/kb/196955/it</a
                  >
                </p>
              </li>
              <li>
                <p>
                  <strong>Chrome</strong>:
                  <a
                    href="https://support.google.com/chrome/answer/95647?hl=it"
                    target="_blank"
                    >https://support.google.com/chrome/answer/95647?hl=it</a
                  >
                </p>
              </li>
              <li>
                <p>
                  <strong>Firefox</strong>:
                  <a
                    href="https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie"
                    target="_blank"
                    >https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie</a
                  >
                </p>
              </li>
              <li>
                <p>
                  <strong>Safari</strong>:
                  <a
                    href="https://support.apple.com/kb/PH17191?locale=it_IT"
                    target="_blank"
                    >https://support.apple.com/kb/PH17191?locale=it_IT</a
                  >
                </p>
              </li>
              <li>
                <p>
                  <strong>Opera</strong>:
                  <a
                    href="http://help.opera.com/Windows/10.00/it/cookies.html"
                    target="_blank"
                    >http://help.opera.com/Windows/10.00/it/cookies.html</a
                  >
                </p>
              </li>
            </ul>

            <p>
              Fai le tue scelte con attenzione. Bloccando indiscriminatamente la
              ricezione di tutti i cookie, inclusi quelli tecnici, senza
              prevedere una specifica eccezione per il Sito, potresti, infatti,
              non essere più in grado di navigare sul Sito o di usufruire in
              tutto o in parte delle sue funzionalità. Inoltre, cancellando
              tutti i cookie dal browser, anche i cookie tecnici potrebbero
              essere rimossi e, pertanto, potresti rimuovere le preferenze che
              hai impostato utilizzando il Sito o non ritrovare più i prodotti o
              servizi inseriti nel tuo carrello.
            </p>
          </li>
          <li>
            <p>
              <strong class="sottolineato"
                >Utilizza gli strumenti interattivi o quelli forniti dalle terze
                parti</strong
              >
            </p>

            <p>
              Per
              <span class="sottolineato"
                >disabilitare i cookie di terze parti</span
              >, consulta le informative privacy dei terzi che installano cookie
              analytics (vedi il precedente
              <a
                href="#"
                onclick="Scroll_a_elemento('paragrafo_c'); return false;"
                >paragrafo C</a
              >) o cookie di profilazione (vedi il precedente
              <a
                href="#"
                onclick="Scroll_a_elemento('paragrafo_d'); return false;"
                >paragrafo D</a
              >) per conoscere gli altri strumenti a tua disposizione per
              gestire, disabilitare e cancellare i cookie, e più in generale per
              opporti al loro utilizzo. Ricordati che, disabilitando i cookie di
              terze parti, (i) ti opponi al loro utilizzo non soltanto sul Sito
              ma su tutti i siti Internet su cui tali cookie sono utilizzati e
              (ii) la possibilità di navigare sul Sito ed utilizzarne le
              funzionalità non sarà in alcun modo pregiudicata. Quando
              disabiliti i cookie di terze parti visualizzerai comunque il
              banner nell'home page sul Sito relativo ai cookie; in questo caso,
              per&ograve;, chiudendo il banner, scorrendo l'<em>home page</em> o
              cliccando qualunque elemento dell'home page al di fuori del
              banner, non riceverai nessun cookie di terza parte che hai
              correttamente disabilitato.
            </p>
          </li>
          <li>
            <p>
              <strong class="sottolineato"
                >Utilizza il sito Internet
                <a href="http://www.youronlinechoices.eu/it/" target="_blank"
                  >www.youronlinechoices.eu/it/</a
                ></strong
              >
            </p>

            <p>
              Your Online Choices è un sito Internet gestito dall'associazione
              non-profit
              <em>European Interactive Digital Advertising Alliance</em> (EDAA),
              la cui versione in lingua italiana è raggiungibile all'indirizzo
              <a href="http://www.youronlinechoices.eu/it/" target="_blank"
                >www.youronlinechoices.eu/it/</a
              >, che fornisce informazioni sulla pubblicità comportamentale
              basata sui cookie di profilazione (<a
                href="http://www.youronlinechoices.com/it/a-proposito"
                target="_blank"
                >http://www.youronlinechoices.com/it/a-proposito</a
              >) e consente agli utenti di Internet di opporsi facilmente
              (opt-out) all'installazione dei principali cookie di profilazione
              installati dagli operatori pubblicitari ed utilizzati su siti
              Internet (<a
                href="http://www.youronlinechoices.com/it/le-tue-scelte"
                target="_blank"
                >http://www.youronlinechoices.com/it/le-tue-scelte</a
              >). Prima di utilizzare questo strumento, ti consigliamo di
              leggere attentamente le condizioni generali di servizio del sito
              Your Online Choices (<a
                href="http://www.youronlinechoices.com/it/condizioni-generali-di-servizio"
                target="_blank"
                >http://www.youronlinechoices.com/it/condizioni-generali-di-servizio</a
              >), le domande frequenti (FAQ) (<a
                href="http://www.youronlinechoices.com/it/faqs"
                target="_blank"
                >http://www.youronlinechoices.com/it/faqs</a
              >) e la guida per l'utente (<a
                href="http://www.youronlinechoices.com/it/help"
                target="_blank"
                >http://www.youronlinechoices.com/it/help</a
              >).
            </p>

            <p>
              Utilizza consapevolmente Your Online Choices. Sebbene, infatti,
              Your Online Choices riunisca molte delle più importanti società
              del mondo pubblicitario che utilizzano cookie, alcune delle terze
              parti che installano cookie attraverso il Sito potrebbero non aver
              aderito a Your Online Choices. Pertanto, l'utilizzo di Your Online
              Choices non garantisce che si riceveranno cookie di terze parti
              navigando sul Sito. Ricordati, inoltre, che eliminando tutti i
              cookie dal proprio browser, anche i cookie tecnici rilasciati da
              Your Online Choices per ricordare le tue scelte potrebbero essere
              eliminati, rendendo nuovamente attivi i cookie di terze parti.
            </p>
          </li>
        </ol>
      </li>
      <li>
        <h3>Modalità di trattamento e tempi di conservazione dei dati</h3>

        <p>
          Come evidenziato nella premessa di questa informativa, è possibile che
          L'Associazione raccolga e tratti alcuni tuoi dati personali attraverso
          i cookie che essa veicola direttamente sul Sito (cookie di prima
          parte). L'Associazione agisce in qualità di titolare del trattamento
          di questi dati, in conformità alle previsioni del Regolamento.
          Tratteremo i tuoi dati solo con strumenti elettronici, in maniera
          totalmente automatizzata e senza intermediazione umana. Pertanto, i
          nostri dipendenti e collaboratori non accederanno mai al contenuto dei
          tuoi dati personali ottenuti attraverso i cookie, il che significa che
          non saranno mai in grado di accedere a e / o avere PII (Personally
          Identifiable Information), cioè informazioni da cui sarà possibile
          identificarti direttamente.
        </p>

        <p>
          Alcuni dei nostri dipendenti e collaboratori, da noi nominati quali
          incaricati del trattamento, potrebbero effettuare degli interventi di
          manutenzione sui sistemi informatici che ospitano i tuoi dati, senza
          poter mai accedere al loro effettivo contenuto. I dati personali
          potrebbero essere memorizzati nei server gestiti da soggetti terzi
          (es. fornitori di sistemi informatici) o potrebbero essere gestiti da
          soggetti specializzati nella pubblicità online, che agiscono in
          qualità di responsabili esterni del trattamento sulla base di
          un'apposita nomina per iscritto da parte delL'Associazione. Ti
          informiamo che, nel rispetto dei presupposti e delle garanzie
          stabilite dal Regolamento, i tuoi dati potrebbero essere trasferiti in
          Paesi non appartenenti all'Area Economica Europea, che potrebbero non
          offrire un livello di tutela della privacy e della protezione dei dati
          personali paragonabile a quello garantito dalle leggi italiane ed
          europee sulla privacy, ma laddove L'Associazione agirà come titolare
          terremo in massima considerazione la sicurezza dei dati, pertanto
          procederemo nella gestione di tali trasferimenti con tutte le dovute
          cautele e garanzie. I tuoi dati personali non saranno comunicati a
          terzi titolari del trattamento nè; saranno diffusi.
        </p>

        <p>
          I tuoi dati personali saranno conservati per i tempi strettamente
          necessari ad espletare le finalità primarie illustrate nella presente
          informativa, o comunque secondo quanto necessario per la tutela dei
          diritti sia degli interessi sia degli Utenti che delL'Associazione.
        </p>
      </li>
      <li>
        <h3>I tuoi diritti</h3>

        <p>
          Per esercitare i tuoi diritti, o ottenere alcuna ulteriore
          informazione o chiarimento in relazione alla presente Informativa
          Cookie, ti preghiamo di contattare L'Associazione con le seguenti
          modalità:
        </p>

        <ul class="lista_puntata">
          <li>
            <p>
              Inviando una raccomandata A/R alla sede legale del Titolare Blitz
              a.s.d, via Della Grada 8/a-b-c, 40122 Bologna;
            </p>
          </li>
          <li>
            <p>
              Inviando un messaggio di posta elettronica all&rsquo;indirizzo
              <a href="mailto:bologna@fugaimpossibile.it">bologna@fugaimpossibile.it</a>;
            </p>
          </li>
        </ul>

        <p>
          Ai sensi del Regolamento, L'Associazione informa che gli Utenti hanno
          il diritto di ottenere l'indicazione:
        </p>

        <ol class="lista_numerata_romani">
          <li>
            <p>dell'origine dei dati personali;</p>
          </li>
          <li>
            <p>delle finalità e modalità del trattamento;</p>
          </li>
          <li>
            <p>
              della logica applicata in caso di trattamento effettuato con
              l'ausilio di strumenti elettronici;
            </p>
          </li>
          <li>
            <p>degli estremi identificativi del titolare e dei responsabili;</p>
          </li>
          <li>
            <p>
              dei soggetti o delle categorie di soggetti ai quali i dati
              personali possono essere comunicati o che possono venirne a
              conoscenza in qualità di responsabili o incaricati.
            </p>
          </li>
        </ol>

        <p>Inoltre, gli Utenti hanno il diritto di ottenere:</p>

        <ol class="lista_alfabetica">
          <li>
            <p>
              l'<strong>accesso</strong>, l'<strong>aggiornamento</strong>, la
              <strong>rettificazione</strong> ovvero, quando vi hanno interesse,
              l'<strong>integrazione</strong> dei dati;
            </p>
          </li>
          <li>
            <p>
              la <strong>cancellazione</strong>, la
              <strong>trasformazione in forma anonima</strong> o il
              <strong>blocco</strong> dei dati trattati in violazione di legge,
              compresi quelli di cui non è necessaria la conservazione in
              relazione agli scopi per i quali i dati sono stati raccolti o
              successivamente trattati;
            </p>
          </li>
          <li>
            <p>
              l'attestazione che le operazioni di cui alle lettere a) e b) sono
              state portate a conoscenza, anche per quanto riguarda il loro
              contenuto, di coloro ai quali i dati sono stati comunicati o
              diffusi, eccettuato il caso in cui tale adempimento si rivela
              impossibile o comporta un impiego di mezzi manifestamente
              sproporzionato rispetto al diritto tutelato.
            </p>
          </li>
        </ol>

        <p>Inoltre, gli Utenti hanno:</p>

        <ol class="lista_alfabetica">
          <li>
            <p>
              il diritto di <strong>revocare il consenso</strong> in qualsiasi
              momento, qualora il trattamento si basi sul loro consenso;
            </p>
          </li>
          <li>
            <p>
              (laddove applicabile) il diritto alla
              <strong>portabilità dei dati</strong> (diritto di ricevere tutti i
              dati personali che li riguardano in un formato strutturato, di uso
              comune e leggibile da dispositivo automatico), il diritto alla
              limitazione del trattamento dei dati personali e il diritto alla
              cancellazione ('<strong>diritto all'oblio</strong>');
            </p>
          </li>
          <li>
            <p>il diritto di <strong>opporsi</strong>:</p>

            <ol class="lista_numerata_romani">
              <li>
                <p>
                  in tutto o in parte, per motivi legittimi al trattamento dei
                  dati personali che li riguardano, ancorchè; pertinenti allo
                  scopo della raccolta;
                </p>
              </li>
              <li>
                <p>
                  in tutto o in parte, al trattamento di dati personali che li
                  riguardano a fini di invio di materiale pubblicitario o di
                  vendita diretta o per il compimento di ricerche di mercato o
                  di comunicazione commerciale;
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              qualora ritenessero che il trattamento che li riguarda violi il
              Regolamento, il diritto di
              <strong>proporre reclamo a un'Autorità di controllo</strong>
              (nello Stato membro in cui risiedono abitualmente, in quello in
              cui lavorano oppure in quello in cui si è verificata la presunta
              violazione). L'Autorità di controllo italiana è il
              <strong>Garante per la protezione dei dati personali</strong>, con
              sede in Piazza di Monte Citorio n. 121, 00186 &ndash; Roma (<a
                href="https://www.garanteprivacy.it/"
                target="_blank"
                >https://www.garanteprivacy.it/</a
              >).
            </p>
          </li>
        </ol>
      </li>
    </ol>

    <p class="centra">***</p>

    <p>
      L'Associazione non è responsabile per l&rsquo;aggiornamento di tutti i
      link visualizzabili nella presente Informativa Cookies, pertanto ogni
      qualvolta un link non sia funzionante e/o aggiornato, gli Utenti
      riconoscono ed accettano che dovranno sempre far riferimento al documento
      e/o sezione dei siti internet richiamati da tale link.
    </p>
  </article>
</div>
