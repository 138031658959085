<div class="container">
  <div class="content">
      <section>Attività riservata ai soci - Fuga Impossibile - Escape Room - BLITZ APS - P.IVA e c.f. 04163641204 - cod. dest. KRRH6B9</section>
       <section>
           <a href="mailto:bologna@fugaimpossibile.it">bologna@fugaimpossibile.it</a> -
           <a href="mailto:blitzaps@pec.it">blitzaps@pec.it</a> -
       </section>
      <section>
          <a href="tel:393478932984">+39 347 89 32 984</a> (Daniele)
      </section>
      <div style="display: flex; flex-direction: row; gap: 40px; align-items: center">
        <img src="assets/img/logo-fugaimpossibile.png" height="60" />
        <img src="assets/img/blitz-aps-logo.png" height="40" />
      </div>
      <div class="link">
          <section><a style="cursor: pointer" (click)="isPrivacyVisible = true">Privacy Sito</a></section>
          <section><a style="cursor: pointer" (click)="isCookieVisible = true">Privacy Cookie</a></section>
          <section><a href="assets/pdf/Statuto.pdf">Statuto</a></section>
      </div>
  </div>
</div>
<div *ngIf="isCookieVisible" class="privacy-container">
    <div class="privacy-close-button" (click)="isCookieVisible = false">
        <i class="fa fa-times" aria-hidden="true"></i>
    </div>
    <app-privacy-cookie></app-privacy-cookie>
</div>
<div *ngIf="isPrivacyVisible" class="privacy-container">
    <div class="privacy-close-button" (click)="isPrivacyVisible = false">
        <i class="fa fa-times" aria-hidden="true"></i>
    </div>
    <app-privacy-sito></app-privacy-sito>
</div>
