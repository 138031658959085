import { LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { AppComponent } from "./app.component";
import { RouterOutlet } from "@angular/router";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DatePipe, NgOptimizedImage } from "@angular/common";
import { MatGridListModule } from "@angular/material/grid-list";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { HomeComponent } from "./components/home/home.component";
import { AppRoutingModule } from "./app-routing.module";
import { StanzeComponent } from "./components/stanze/stanze.component";
import { MatCardModule } from "@angular/material/card";
import { StanzaComponent } from "./components/stanza/stanza.component";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { ComeFunzionaComponent } from "./components/come-funziona/come-funziona.component";
import { ContattiComponent } from "./components/contatti/contatti.component";
import { ChiGiocaComponent } from "./components/chi-gioca/chi-gioca.component";
import { FooterComponent } from "./components/footer/footer.component";
import { PrenotaComponent } from "./components/prenota/prenota.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { CalendarComponent } from "./components/calendar/calendar.component";
import { FestaECompleanniComponent } from "./components/festa-e-compleanni/festa-e-compleanni.component";
import { TeamBuildingComponent } from "./components/team-building/team-building.component";
import { PrivacyCookieComponent } from "./components/privacy-cookie/privacy-cookie.component";
import { PrivacySitoComponent } from "./components/privacy-sito/privacy-sito.component";
import { SpinnerComponent } from "./shared/components/spinner/spinner.component";
import { SpinnerService } from "./shared/services/spinner.service";
import { SpinnerInterceptor } from "./shared/components/interceptors/spinner.interceptor";
import { LoginComponent } from "./components/login/login.component";
import { MatInputModule } from "@angular/material/input";
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    StanzeComponent,
    StanzaComponent,
    ComeFunzionaComponent,
    ContattiComponent,
    ChiGiocaComponent,
    FooterComponent,
    PrenotaComponent,
    CalendarComponent,
    FestaECompleanniComponent,
    TeamBuildingComponent,
    PrivacyCookieComponent,
    PrivacySitoComponent,
    SpinnerComponent,
    LoginComponent,
  ],
  imports: [
    AppRoutingModule,
    HttpClientModule,
    BrowserModule,
    RouterOutlet,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    BrowserAnimationsModule,
    NgOptimizedImage,
    MatGridListModule,
    MatCardModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    ReactiveFormsModule,
  ],
  providers: [
    DatePipe,
    { provide: LOCALE_ID, useValue: "it-IT" },
    SpinnerService,
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
