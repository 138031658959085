<div
  class="container"
  [ngStyle]="
    isVisibleSummary ? { 'min-height': 'calc(100vh - 408px)' } : undefined
  "
>
  <div class="header">
    <h1
      class="title-header"
      id="stanze-title"
      [@fadeInOut]="isVisibleTitolo ? 'visible' : 'hidden'"
    >
      Le Stanze
    </h1>
  </div>
  <div class="summary" *ngIf="isVisibleSummary">
    <p>
      State indagando per conto dei servizi segreti sull'operato del professor
      Igor Stanislaw Zilosky, soprannominato <strong>Bartezzagus</strong>,
      sospettato di ricerche scientifiche con finalità criminali.
    </p>
  </div>
  <div class="content">
    <app-stanza
      *ngFor="let stanza of stanze"
      #appStanze
      [ngStyle]="{
        'flex-basis': selectedDiv === stanza.id_stanza ? '100%' : 'auto',
        order: selectedDiv === stanza.id_stanza ? '-1' : '0'
      }"
      (click)="onStanzaClick(stanza.id_stanza)"
      [stanzaId]="'stanza-' + stanza.id_stanza"
      [isVisible]="isVisibleStanze[stanza.id_stanza - 1]"
      [isClicked]="selectedDiv === stanza.id_stanza"
      [title]="stanza.nome"
      [imgStanza]="stanza.immagine"
      [description]="stanza.descrizione"
      [duration]="stanza.durata + ' MIN'"
      [difficulty]="stanza.difficolta"
      [players]="stanza.capacita_min + '-' + stanza.capacita_max"
    ></app-stanza>
  </div>
</div>
