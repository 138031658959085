import {
  AfterViewChecked,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  ViewChildren,
} from "@angular/core";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { CalendarService } from "../../shared/services/calendar.service";
import { StanzeService } from "../../services/stanze.service";
import { first } from "rxjs";
import { Meta, Title } from "@angular/platform-browser";
@Component({
  selector: "app-stanze",
  templateUrl: "./stanze.component.html",
  styleUrls: ["./stanze.component.css"],
  animations: [
    trigger("fadeInOut", [
      state(
        "hidden",
        style({
          opacity: 0,
        }),
      ),
      state(
        "visible",
        style({
          opacity: 1,
        }),
      ),
      transition("hidden => visible", animate("500ms ease-in")),
      transition("visible => hidden", animate("500ms ease-out")),
    ]),
  ],
})
export class StanzeComponent implements OnInit, OnDestroy, AfterViewChecked {
  @Input() isVisibleTitolo = true;
  @Input() isVisibleStanze = [true, true, true, true, true];

  @ViewChildren("appStanze") appStanze!: QueryList<any>;

  stanze: any;
  selectedDiv: number = 0;

  isSelectedDivChanged = false;
  isVisibleSummary = false;

  constructor(
    public calendarService: CalendarService,
    private _location: Location,
    private _el: ElementRef,
    private _render: Renderer2,
    private _router: Router,
    private _route: ActivatedRoute,
    private _stanzeService: StanzeService,
    private _meta: Meta,
    private _titleService: Title,
  ) {}

  ngOnInit() {
    this._stanzeService
      .getStanze()
      .pipe(first())
      .subscribe((stanze) => {
        this.stanze = stanze;
      });
    setTimeout(() => {
      this.selectedDiv = +this._route.snapshot.queryParams["selectedDiv"];
    });
    window.scrollTo(0, 0);
    if (this._location.path().includes("stanze")) {
      this._titleService.setTitle(
        "Le nostre stanze - Fuga Impossibile - Bologna Escape Room",
      );
      this._meta.updateTag({
        name: "description",
        content:
          "Esplora tutte le stanze disponibili nella nostra escape room.",
      });
      this._meta.updateTag({
        name: "keywords",
        content:
          "stanze, room, fuga impossibile, bologna, escape room, avventura, divertimento, gioco, enigmi, azione",
      });
      this.isVisibleSummary = true;
      this._render.setStyle(
        this._el.nativeElement.querySelector(".container"),
        "padding",
        "120px 0 40px 0",
      );
    }
  }

  ngOnDestroy() {
    this.calendarService.nascondi();
  }

  ngAfterViewChecked() {
    if (this.isSelectedDivChanged) {
      const selectedDivComponent =
        this.appStanze.toArray()[this.selectedDiv - 1];
      if (selectedDivComponent) {
        setTimeout(() => {
          selectedDivComponent.scrollToComponent();
        }, 100);
      }
      this.isSelectedDivChanged = false;
    }
  }

  onStanzaClick(numeroStanza: number) {
    if (this._location.path().includes("stanze")) {
      if (this.selectedDiv !== numeroStanza) {
        this.isSelectedDivChanged = this.selectedDiv !== numeroStanza;
        this.selectedDiv = numeroStanza;
        this.calendarService.nascondi();
        for (const appStanza of this.appStanze) {
          appStanza.isButtonVisibled = true;
        }
      }
    } else {
      this._router
        .navigate(["/stanze"], { queryParams: { selectedDiv: numeroStanza } })
        .then(() => {});
    }
  }
}
