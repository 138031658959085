<div class="container">
  <h1>Informativa Privacy agli Utenti</h1>

  <article class="testo">
    <p>
      <strong>Blitz APS</strong>&nbsp;con sede legale in in via Della Grada
      8/a-b-c, Codice Fiscale<small>&nbsp;91438110370</small> (di seguito, il
      &ldquo;<strong>Titolare</strong>&rdquo;), proprietario del sito internet
      www.fugaimpossibile.it (di seguito, il
      &ldquo;<strong>Sito</strong>&rdquo;), in qualit&agrave; di titolare del
      trattamento dei dati personali degli utenti del Sito (di seguito, gli
      &ldquo;<strong>Utenti</strong>&rdquo;) fornisce qui di seguito
      l&rsquo;informativa privacy ai sensi i dell&rsquo;art. 13 del Regolamento
      UE 2016/679 del 27 aprile 2016 (di seguito,
      &ldquo;<strong>Regolamento</strong>&rdquo; e/o &ldquo;<strong
        >Normativa Applicabile</strong
      >&rdquo;).
    </p>

    <p>
      Questo Sito e i servizi eventualmente offerti tramite il Sito sono
      riservati a soggetti che hanno compiuto il diciottesimo anno di
      et&agrave;. Il Titolare non raccoglie quindi dati personali relativi ai
      soggetti minori di anni 18. Su richiesta degli Utenti, il Titolare
      canceller&agrave; tempestivamente tutti i dati personali involontariamente
      raccolti e relativi a soggetti minori di anni 18.
    </p>

    <p>
      Il Titolare tiene nella massima considerazione il diritto alla privacy ed
      alla protezione dei dati personali dei propri Utenti. Per ogni
      informazione in relazione alla presente Informativa Privacy, gli Utenti
      possono contattare il Titolare in qualsiasi momento, utilizzando le
      seguenti modalit&agrave;:
    </p>

    <ul class="lista_puntata">
      <li>
        <p>
          Inviando una lettera raccomandata con ricevuta di ritorno alla sede
          legale del Titolare, Blitz APS, via Della Grada 8/a-b-c, 40122
          Bologna;
        </p>
      </li>
      <li>
        <p>
          Inviando un&#39;e-mail a
          <a href="mailto:bologna@fugaimpossibile.it">bologna@fugaimpossibile.it</a>;
        </p>
      </li>
    </ul>

    <p>
      Il Titolare non ha individuato la figura del Responsabile della protezione
      dei dati (RPD o DPO), in quanto non &egrave; soggetto all&rsquo;obbligo di
      designazione previsto dall&rsquo;art. 37 del Regolamento.
    </p>

    <ol class="lista_numerata" id="paragrafo_1">
      <li>
        <h3>Finalit&agrave; del trattamento</h3>

        <p>
          I dati personali degli Utenti saranno trattati lecitamente dal
          Titolare ai sensi dell&rsquo;art. 6 del Regolamento per le seguenti
          finalit&agrave; di trattamento:
        </p>

        <ol class="lista_alfabetica">
          <li>
            <p>
              <strong class="sottolineato"
                >obblighi contrattuali e fornitura del servizio</strong
              >, per consentire la navigazione del Sito ovvero per dare
              esecuzione alle Condizioni Generali del Sito, che sono accettate
              dall&rsquo;Utente in fase di registrazione e adempiere a
              specifiche richieste dell&#39;Utente. I dati dell&rsquo;Utente
              raccolti dal Titolare a tali fini includono: email, data di
              nascita, genere, provincia, interessi, professione, nonch&eacute;
              tutte le informazioni personali dell&rsquo;Utente eventualmente e
              volontariamente pubblicate. Salvo che l&rsquo;Utente dia al
              Titolare uno specifico e facoltativo consenso al trattamento dei
              propri dati per le ulteriori finalit&agrave; previste ai
              successivi paragrafi, i dati personali dell&rsquo;Utente saranno
              utilizzati dal Titolare al fine esclusivo di accertare
              l&rsquo;identit&agrave; dell&rsquo;Utente evitando cos&igrave;
              possibili truffe o abusi, e contattare l&rsquo;Utente per sole
              ragioni di servizio - ad esempio, inviare notifiche a) per
              consentire la navigazione del Sito ovvero per dare esecuzione al
              Regolamento del gioco, che &egrave; accettato dall&rsquo;Utente in
              fase di registrazione e adempiere a specifiche richieste
              dell&#39;Utente. I dati dell&rsquo;Utente raccolti dal Titolare a
              tali fini includono: il nome, il cognome e l&#39;indirizzo email,
              il numero di telefono, nonch&eacute; tutte le informazioni
              personali dell&rsquo;Utente eventualmente e volontariamente
              pubblicate. Salvo che l&rsquo;Utente dia al Titolare uno specifico
              e facoltativo consenso al trattamento dei propri dati per le
              ulteriori finalit&agrave; previste ai successivi paragrafi, i dati
              personali dell&rsquo;Utente saranno utilizzati dal Titolare al
              fine esclusivo di accertare l&rsquo;identit&agrave;
              dell&rsquo;Utente evitando cos&igrave; possibili truffe o abusi, e
              contattare l&rsquo;Utente per sole ragioni di servizio - ad
              esempio, inviare notifiche relative ai servizi offerti sul Sito
              relativi ai prodotti del Titolare. Fermo restando quanto previsto
              altrove in questa Informativa Privacy, in nessun caso il Titolare
              render&agrave; accessibili agli altri Utenti e/o a terzi i dati
              personali degli Utenti.
            </p>
          </li>
          <li>
            <p>
              <strong class="sottolineato"
                >finalit&agrave; amministrativo-contabili</strong
              >, ovvero per svolgere attivit&agrave; di natura organizzativa,
              amministrativa, finanziaria e contabile, quali attivit&agrave;
              organizzative interne ed attivit&agrave; funzionali
              all&#39;adempimento di obblighi contrattuali e precontrattuali;
            </p>
          </li>
          <li>
            <p>
              <strong class="sottolineato">obblighi di legge</strong>, ovvero
              per adempiere ad obblighi previsti dalla legge, da
              un&rsquo;Autorit&agrave;, da un regolamento o dalla normativa
              europea applicabile.
            </p>
          </li>
        </ol>

        <p>
          Il conferimento dei dati personali per le finalit&agrave; di
          trattamento sopra indicate &egrave; facoltativo ma necessario,
          poich&eacute; il mancato conferimento degli stessi comporter&agrave;
          l&rsquo;impossibilit&agrave; per l&rsquo;Utente di iscriversi al Sito
          e usufruire dei servizi offerti dal Titolare anche tramite il Sito.
        </p>

        <p>
          I dati personali che sono necessari per il perseguimento delle
          finalit&agrave; di trattamento descritte al presente
          <a href="#" onclick="Scroll_a_elemento('paragrafo_1'); return false;"
            >paragrafo 1</a
          >
          sono indicati all&rsquo;interno del modulo di registrazione sul Sito.
        </p>
      </li>
      <li id="paragrafo_2">
        <h3>Ulteriori finalit&agrave; di trattamento: newsletter</h3>

        <p>
          Con il
          <strong class="sottolineato">consenso libero e facoltativo</strong>
          dell&rsquo;Utente, alcuni dati personali dell&rsquo;Utente (ovvero
          nome, cognome, indirizzo e-mail) potranno essere trattati dal Titolare
          anche per finalit&agrave; di invio della newsletter. Pertanto
          l&rsquo;Utente ricever&agrave; dal Titolare una newsletter periodica
          che conterr&agrave; informazioni in relazione a notizie rilevanti
          riguardanti il Sito ed i servizi offerti tramite il Sito stesso (come
          a titolo esemplificativo ma non esaustivo, nel caso di apertura di
          nuovi episodi del gioco e/o altre comunicazioni inerenti al gioco
          stesso).
        </p>

        <p>
          In caso di mancato consenso, la possibilit&agrave; di iscriversi al
          Sito non sar&agrave; in alcun modo pregiudicata.
        </p>

        <p>
          In caso di consenso, l&rsquo;Utente potr&agrave; in qualsiasi momento
          revocare lo stesso, facendone richiesta al Titolare con le
          modalit&agrave; indicate al successivo
          <a href="#" onclick="Scroll_a_elemento('paragrafo_5'); return false;"
            >paragrafo 5</a
          >.
        </p>

        <p>
          L&rsquo;Utente potr&agrave; inoltre opporsi facilmente ad ulteriori
          invii di comunicazioni promozionali anche cliccando sull&#39;apposito
          link per la revoca del consenso, che &egrave; presente in ciascuna
          email contenente la newsletter. Effettuata la revoca del consenso, il
          Titolare invier&agrave; all&rsquo;Utente un messaggio di posta
          elettronica per confermare l&rsquo;avvenuta revoca del consenso.
        </p>
      </li>
      <li>
        <h3>
          Modalit&agrave; di trattamento e tempi di conservazione dei dati
        </h3>

        <p>
          Il Titolare tratter&agrave; i dati personali degli Utenti mediante
          strumenti manuali ed informatici, con logiche strettamente correlate
          alle finalit&agrave; stesse e, comunque, in modo da garantire la
          sicurezza e la riservatezza dei dati stessi.
        </p>

        <p>
          I dati personali degli Utenti del Sito saranno conservati per i tempi
          strettamente necessari ad espletare le finalit&agrave; primarie
          illustrate al precedente
          <a href="#" onclick="Scroll_a_elemento('paragrafo_1'); return false;"
            >paragrafo 1</a
          >, o comunque secondo quanto necessario per la tutela in sede
          civilistica degli interessi sia degli Utenti che del Titolare.
        </p>

        <p>
          <em
            >Nei casi di cui al precedente&nbsp;<a
              href="#"
              onclick="Scroll_a_elemento('paragrafo_2'); return false;"
              >paragrafo 2</a
            >
            i dati personali degli Utenti saranno conservati per i tempi
            strettamente necessari ad espletare la finalit&agrave; illustrata e,
            in ogni caso, per non pi&ugrave; di ventiquattro (24) mesi.</em
          >
        </p>
      </li>
      <li>
        <h3>Ambito di comunicazione e diffusione dei dati</h3>

        <p>
          Potranno venire a conoscenza dei dati personali degli Utenti i
          dipendenti e/o collaboratori del Titolare incaricati di gestire il
          Sito. Tali soggetti, che sono formalmente nominati dal Titolare quali
          &ldquo;<strong>Incaricati del Trattamento</strong>&rdquo;, tratteranno
          i dati dell&rsquo;Utente esclusivamente per le finalit&agrave;
          indicate nella presente Informativa Privacy e nel rispetto delle
          previsioni della Normativa Applicabile.
        </p>

        <p>
          Potranno inoltre venire a conoscenza dei dati personali degli Utenti i
          soggetti terzi che potranno trattare dati personali per conto del
          Titolare in qualit&agrave; di &ldquo;<strong
            >Responsabili Esterni del Trattamento</strong
          >&rdquo;, quali, a titolo esemplificativo, fornitori di servizi
          informatici e logistici funzionali all&rsquo;operativit&agrave; del
          Sito, fornitori di servizi in <em>outsourcing</em> o
          <em>cloud computing</em>, professionisti e consulenti, spedizionieri e
          simili.
        </p>

        <p>
          Gli Utenti hanno il diritto di ottenere una lista degli eventuali
          responsabili del trattamento nominati dal Titolare, facendone
          richiesta al Titolare con le modalit&agrave; indicate al successivo
          <a href="#" onclick="Scroll_a_elemento('paragrafo_5'); return false;"
            >paragrafo 5</a
          >.
        </p>
      </li>
      <li id="paragrafo_5">
        <h3>Diritti degli Interessati</h3>

        <p>
          Gli Utenti potranno esercitare i diritti garantiti loro dalla
          Normativa Applicabile, contattando il Titolare con le seguenti
          modalit&agrave;:
        </p>

        <ul class="lista_puntata">
          <li>
            <p>
              Inviando una lettera raccomandata con ricevuta di ritorno alla
              sede legale del Titolare, <small>Blitz APS</small>, via Della
              Grada 8/a-b-c, 40122 Bologna;
            </p>
          </li>
          <li>
            <p>
              Inviando un&#39;e-mail a
              <a href="mailto:bologna@fugaimpossibile.it">bologna@fugaimpossibile.it</a>;
            </p>
          </li>
        </ul>

        <p>
          Ai sensi della Normativa Applicabile, il Titolare informa che gli
          Utenti hanno il diritto di ottenere l&#39;indicazione:
        </p>

        <ol class="lista_numerata_romani">
          <li>
            <p>dell&#39;origine dei dati personali;</p>
          </li>
          <li>
            <p>delle finalit&agrave; e modalit&agrave; del trattamento;</p>
          </li>
          <li>
            <p>
              della logica applicata in caso di trattamento effettuato con
              l&#39;ausilio di strumenti elettronici;
            </p>
          </li>
          <li>
            <p>degli estremi identificativi del titolare e dei responsabili;</p>
          </li>
          <li>
            <p>
              dei soggetti o delle categorie di soggetti ai quali i dati
              personali possono essere comunicati o che possono venirne a
              conoscenza in qualit&agrave; di responsabili o incaricati.
            </p>
          </li>
        </ol>

        <p>Inoltre, gli Utenti hanno il diritto di ottenere:</p>

        <ol class="lista_alfabetica">
          <li>
            <p>
              l&rsquo;<strong>accesso</strong>,
              l&#39;<strong>aggiornamento</strong>, la
              <strong>rettificazione</strong> ovvero, quando vi hanno interesse,
              l&#39;<strong>integrazione</strong> dei dati;
            </p>
          </li>
          <li>
            <p>
              la <strong>cancellazione</strong>, la
              <strong>trasformazione in forma anonima</strong> o il
              <strong>blocco</strong> dei dati trattati in violazione di legge,
              compresi quelli di cui non &egrave; necessaria la conservazione in
              relazione agli scopi per i quali i dati sono stati raccolti o
              successivamente trattati;
            </p>
          </li>
          <li>
            l&#39;attestazione che le operazioni di cui alle lettere a) e b)
            sono state portate a conoscenza, anche per quanto riguarda il loro
            contenuto, di coloro ai quali i dati sono stati comunicati o
            diffusi, eccettuato il caso in cui tale adempimento si rivela
            impossibile o comporta un impiego di mezzi manifestamente
            sproporzionato rispetto al diritto tutelato.
          </li>
        </ol>

        <p>Inoltre, gli Utenti hanno:</p>

        <ol class="lista_alfabetica">
          <li>
            <p>
              il diritto di <strong>revocare il consenso</strong> in qualsiasi
              momento, qualora il trattamento si basi sul loro consenso;
            </p>
          </li>
          <li>
            <p>
              il diritto alla
              <strong>portabilit&agrave; dei dati</strong> (diritto di ricevere
              tutti i dati personali che li riguardano in un formato
              strutturato, di uso comune e leggibile da dispositivo automatico),
              il diritto alla <strong>limitazione del trattamento</strong> dei
              dati personali e il
              <strong>diritto alla cancellazione</strong> (&ldquo;diritto
              all&rsquo;oblio&rdquo;);
            </p>
          </li>
          <li>
            <p>il <strong>diritto di opporsi</strong>:</p>

            <ol class="lista_numerata_romani">
              <li>
                <p>
                  in tutto o in parte, per motivi legittimi al trattamento dei
                  dati personali che li riguardano, ancorch&eacute; pertinenti
                  allo scopo della raccolta;
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              qualora ritenessero che il trattamento che li riguarda violi il
              Regolamento, il diritto di
              <strong
                >proporre reclamo a un&rsquo;Autorit&agrave; di
                controllo</strong
              >
              (nello Stato membro in cui risiedono abitualmente, in quello in
              cui lavorano oppure in quello in cui si &egrave; verificata la
              presunta violazione). L&rsquo;Autorit&agrave; di controllo
              italiana &egrave; il
              <strong>Garante per la protezione dei dati personali</strong>, con
              sede in Piazza di Monte Citorio n. 121, 00186 &ndash; Roma (<a
                href="https://www.garanteprivacy.it/"
                target="_blank"
                >https://www.garanteprivacy.it/</a
              >).
            </p>
          </li>
        </ol>
      </li>
    </ol>

    <p class="centra">***</p>

    <p>
      Il Titolare non &egrave; responsabile per l&rsquo;aggiornamento di tutti i
      link visualizzabili nella presente Informativa Privacy, pertanto ogni
      qualvolta un link non sia funzionante e/o aggiornato, gli Utenti
      riconoscono ed accettano che dovranno sempre far riferimento al documento
      e/o sezione dei siti internet richiamati da tale link.
    </p>
  </article>
</div>
