<div class="container">
  <h2 class="title" id="come-funziona-title">Team Building</h2>
  <div class="content">
    <img src="assets/img/team-building.png" />
    <div class="box">
      <section class="title-box">Team building a Bologna!</section>
      <p>
        I giochi di escape room si prestano molto bene per attività di team
        building il cui obiettivo è la formazione di un gruppo di persone in
        ambito lavorativo.
      </p>
      <p>
        Se sei un dirigente d'azienda, ecco un'ottima occasione per migliorare i
        rapporti tra i dipendenti e metterli nelle condizioni di cooperare e
        collaborare per raggiungere un traguardo comune.
      </p>
      <p>
        Se sei un dipendente puoi ugualmente suggerire al tuo responsabile o al
        tuo capo questa opportunità.
      </p>
      <p>
        In soli 60 minuti i partecipanti vivranno una esperienza nuova nella
        quale sarà fondamentale sviluppare la comunicazione, l'interazione e lo
        scambio di idee con i colleghi. I nostri enigmi richiedono una varietà
        di abilità tali che solo un gruppo che lavora e collabora insieme può
        affrontare.
      </p>
      <p>
        Avrai tutto il nostro supporto per organizzare e realizzare al meglio
        l'attività:
      </p>
      <ul>
        <li>
          Progettazione di percorsi, enigmi, prop, storytelling, attori e
          personalizzazione di location a richiesta
        </li>
        <li>Rooms e percorsi mobili</li>
        <li>
          Disponibilità ad organizzare eventi in azienda e in location
          individuate per gruppi fino a 250 partecipanti
        </li>
        <li>Possibilità di di escape room a domicilio</li>
        <li>Esperienze virtuali</li>
        <li>
          Aperitivi, buffet e pernottamento in collaborazione con locali della
          zona
        </li>
      </ul>
    </div>
  </div>
</div>
