import { Component } from '@angular/core';

@Component({
  selector: 'app-privacy-sito',
  templateUrl: './privacy-sito.component.html',
  styleUrls: ['./privacy-sito.component.css']
})
export class PrivacySitoComponent {

}
