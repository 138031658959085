import { Component, HostListener } from "@angular/core";
import { CalendarService } from "./shared/services/calendar.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  title = "fugaimpossibile";

  @HostListener("document:keydown", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === "Escape") {
      this.calendarService.nascondi();
    }
  }

  constructor(public calendarService: CalendarService) {}
}
