import { Component } from '@angular/core';

@Component({
  selector: 'app-chi-gioca',
  templateUrl: './chi-gioca.component.html',
  styleUrls: ['./chi-gioca.component.css']
})
export class ChiGiocaComponent {

}
