import { Component, HostListener } from "@angular/core";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"],
})
export class FooterComponent {
  isCookieVisible: boolean = false;
  isPrivacyVisible: boolean = false;

  @HostListener("document:keydown", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === "Escape") {
      this.isCookieVisible = false;
      this.isPrivacyVisible = false;
    }
  }
}
