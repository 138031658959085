import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Time } from "@angular/common";
const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};
@Injectable({
  providedIn: "root",
})
export class PrenotazioneService {
  constructor(private _http: HttpClient) {}

  getPrenotazioni(stanzaId: number, dataPrenotazione: string) {
    return this._http.get(
      `/api/prenotazione/${stanzaId}?dataPrenotazione=${dataPrenotazione}`,
      httpOptions,
    );
  }

  makePrenotazione(stanzaId: number, dataPrenotazione: string, orario: string) {
    return this._http.put(
      `/api/prenotazione/${stanzaId}`,
      { dataPrenotazione, orario, idUtente: 1, numeroPartecipanti: 5 },
      httpOptions,
    );
  }
}
