import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  first,
  interval,
  Subject,
  Subscription,
  takeUntil,
  takeWhile,
} from "rxjs";
import { HomeService } from "../../services/home.service";
import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit, OnDestroy {
  @ViewChild("banner ", { read: ElementRef }) banner!: ElementRef;
  private createdDiv!: HTMLElement;
  backgroundImage = "sfondo1.webp";
  text = "Chiusi in una stanza!";
  subText = "Vedi le stanze";
  urlLink = "/stanze";
  fragment: string = "";

  isVisibleTitolo = [false, false];
  isVisibleStanze = [false, false, false, false, false];
  isVisibleComeFunzionaElementi = new Array(12).fill(false);
  isBannerVisibile = true;

  pageIndex = 0;

  private _timerSubscription$!: Subscription;
  private _stopTimer$: Subject<void> = new Subject<void>();
  private _touchStartX!: number;
  private _isScrolling: boolean = false;

  constructor(
    private homeService: HomeService,
    private meta: Meta,
    private titleService: Title,
  ) {}

  @HostListener("window:scroll", [])
  onScroll() {
    this.checkVisibility();
  }

  @HostListener("touchstart", ["$event"])
  onTouchStart(event: TouchEvent): void {
    if (event.touches.length === 1) {
      this._touchStartX = event.touches[0].pageX;
    }
  }
  onTouchMove(event: TouchEvent): void {
    if (!this._isScrolling && event.touches.length === 1) {
      const touch = event.touches[0];
      const deltaX = touch.pageX - this._touchStartX;
      if (Math.abs(deltaX) > 150) {
        this._isScrolling = true;
        if (deltaX < 0) {
          this.onClick(this.pageIndex < 3 ? this.pageIndex + 1 : 0);
        } else {
          this.onClick(this.pageIndex > 0 ? this.pageIndex - 1 : 0);
        }
      }
    }
  }

  ngOnInit() {
    this.titleService.setTitle("Fuga Impossibile - Bologna Escape Room");
    this.meta.updateTag({
      name: "description",
      content: "Fuga Impossibile - Bologna Escape Room",
    });
    this.meta.updateTag({
      name: "keywords",
      content:
        "fuga impossibile, bologna, escape room, avventura, divertimento, gioco, enigmi, azione",
    });
    this.homeService
      .home()
      .pipe(first())
      .subscribe({
        next: (result) => {
          console.log(result);
        },
        error: (error) => {
          console.log(error);
        },
      });
    this.startTimer();
  }

  startTimer(): void {
    this._timerSubscription$ = interval(7000)
      .pipe(
        takeWhile(() => !this._stopTimer$.closed),
        takeUntil(this._stopTimer$),
      )
      .subscribe(() => {
        this.pageIndex = this.pageIndex < 3 ? this.pageIndex + 1 : 0;
        this.onClick(this.pageIndex);
      });
  }

  stopTimer(): void {
    this._stopTimer$.next();
    this._stopTimer$.complete();
    if (this._timerSubscription$) {
      this._timerSubscription$.unsubscribe();
    }
  }

  checkVisibility() {
    const titoli = [
      document.getElementById("stanze-title"),
      document.getElementById("come-funziona-title"),
    ];
    const stanze = [
      document.getElementById("stanza-1"),
      document.getElementById("stanza-2"),
      document.getElementById("stanza-3"),
      document.getElementById("stanza-4"),
      document.getElementById("stanza-5"),
    ];

    const comeFunzionaElementi = [
      document.getElementById("icona0"),
      document.getElementById("sottotitolo0"),
      document.getElementById("testo0"),
      document.getElementById("icona1"),
      document.getElementById("sottotitolo1"),
      document.getElementById("testo1"),
      document.getElementById("icona2"),
      document.getElementById("sottotitolo2"),
      document.getElementById("testo2"),
    ];

    titoli.forEach((titolo, index) => {
      if (titolo) {
        const rect = titolo.getBoundingClientRect();
        const windowHeight =
          window.innerHeight || document.documentElement.clientHeight;
        this.isVisibleTitolo[index] =
          rect.top >= 0 && rect.bottom <= windowHeight;
      }
    });

    stanze.forEach((stanza, index) => {
      if (stanza) {
        const rect = stanza.getBoundingClientRect();
        const windowHeight =
          window.innerHeight || document.documentElement.clientHeight;
        this.isVisibleStanze[index] =
          rect.top + 100 <= windowHeight && rect.bottom - 100 >= 0;
      }
    });

    comeFunzionaElementi.forEach((elemento, index) => {
      if (elemento) {
        const rect = elemento.getBoundingClientRect();
        const windowHeight =
          window.innerHeight || document.documentElement.clientHeight;
        this.isVisibleComeFunzionaElementi[index] =
          rect.top + 100 <= windowHeight && rect.bottom - 100 >= 0;
      }
    });
  }
  ngOnDestroy() {
    this.stopTimer();
  }

  onClick(index: number) {
    this.pageIndex = index;
    this.stopTimer();
    this.isBannerVisibile = false;
    if (index === 0) {
      this.backgroundImage = "sfondo1.webp";
      this.text = "Chiusi in una stanza!";
      this.subText = "Vedi le stanze";
      this.urlLink = "/stanze";
      this.fragment = "";
    } else if (index === 1) {
      this.backgroundImage = "sfondo2.webp";
      this.text = "60 Minuti Per Uscire!";
      this.subText = "Come uscire";
      this.urlLink = "/come-funziona";
      this.fragment = "";
    } else if (index === 2) {
      this.backgroundImage = "sfondo3.webp";
      this.text = "L'escape room di Bologna";
      this.subText = "Dove siamo";
      this.urlLink = "/";
      this.fragment = "contattiId";
    } else if (index === 3) {
      this.backgroundImage = "sfondo4.webp";
      this.text = "Per tutte le età";
      this.subText = "Feste e compleanni";
      this.urlLink = "feste-compleanni";
      this.fragment = "";
    }
    setTimeout(() => {
      this.startTimer();
      this.isBannerVisibile = true;
    }, 0);
  }

  onTouchEnd(): void {
    this._isScrolling = false; // Resetta il flag quando il dito viene sollevato
  }
}
