<div
  [style.background]="
    ' linear-gradient(rgba(27, 27, 27, 0.2), rgba(27, 27, 27, 1)), url(\'/assets/img/' +
    backgroundImage +
    '\') no-repeat center/cover'
  "
  class="container"
  (touchmove)="onTouchMove($event)"
  (touchend)="onTouchEnd()"
>
  <div *ngIf="isBannerVisibile">
    <div class="banner">
      <span class="text-center">{{ text }}</span>
      <div class="bar-container">
        <div class="horizontal-bar"></div>
        <div
          class="sub-text"
          [routerLink]="urlLink"
          [fragment]="fragment ? fragment : undefined"
        >
          {{ subText }}
        </div>
      </div>
    </div>
  </div>
  <div class="burger-menu">
    <div
      *ngFor="let i of [0, 1, 2, 3]"
      class="bar"
      [class.active]="pageIndex === i"
      (click)="onClick(i)"
    ></div>
  </div>
</div>
<app-stanze
  [isVisibleTitolo]="isVisibleTitolo[0]"
  [isVisibleStanze]="isVisibleStanze"
>
</app-stanze>
<app-come-funziona
  [isVisibleTitolo]="isVisibleTitolo[1]"
  [isVisibleArray]="isVisibleComeFunzionaElementi"
></app-come-funziona>
<app-chi-gioca></app-chi-gioca>
<app-contatti id="contattiId"></app-contatti>
