import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CalendarService {
  mostraCalendar = new BehaviorSubject<boolean>(false);
  mostraCalendar$ = this.mostraCalendar.asObservable();

  mostra(): void {
    this.mostraCalendar.next(true);
  }

  nascondi(): void {
    this.mostraCalendar.next(false);
  }
}
