<div
  *ngIf="isMenuOpen"
  class="burger-menu"
  [@fadeInOut]="isNavbarVisible ? 'visible' : 'hidden'"
>
  <ul>
    <li *ngFor="let link of links">
      <a
        [routerLink]="link.url"
        [fragment]="link.fragment ? link.fragment : undefined"
        (click)="isMenuOpen = false"
        >{{ link.label }}</a
      >
    </li>
  </ul>
</div>
<mat-toolbar
  class="navbar"
  [@fadeInOut]="isNavbarVisible ? 'visible' : 'hidden'"
>
  <span style="margin-right: 2em; cursor: pointer" [routerLink]="['/']">
    <img
      alt="Fuga Impossibile - Escape Room Bologna"
      src="../../../assets/img/logo-fugaimpossibile-white.png"
      width="140"
      height="70"
    />
  </span>
  <span class="header-link">
    <a
      *ngFor="let link of links; let i = index"
      [class.active]="isClicked[i]"
      [routerLink]="link.url"
      [fragment]="link.fragment ? link.fragment : undefined"
      >{{ link.label }}</a
    >
  </span>
  <span class="example-spacer"> </span>

  <span class="header-button">
    <button (click)="onPrenotaClick()">Prenota ora!</button>
  </span>
  <span class="header-button-login">
    <button mat-button (click)="onLoginClick()">
      <mat-icon>person_outline</mat-icon>
      <span style="font-family: Montserrat, sans-serif; font-size: 11px">
        ACCEDI
      </span>
    </button>
  </span>
  <span class="header-button-small" *ngIf="!isMenuOpen">
    <button mat-icon-button (click)="onPrenotaClick()">
      <mat-icon>date_range</mat-icon>
    </button>
  </span>
  <span class="header-burger">
    <button (click)="isMenuOpen = !isMenuOpen" mat-icon-button>
      <mat-icon *ngIf="!isMenuOpen">menu</mat-icon>
      <mat-icon *ngIf="isMenuOpen">clear</mat-icon>
    </button>
  </span>
</mat-toolbar>
