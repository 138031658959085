<div class="container">
  <div class="header">
    <div class="left-arrow" (click)="onClickMesePrecedente()">
      <mat-icon>arrow_back</mat-icon>
    </div>
    <section class="title">{{ getMonthName(mounth) }} {{ year }}</section>
    <div class="right-arrow" (click)="onClickMeseSuccessivo()">
      <mat-icon>arrow_forward</mat-icon>
    </div>
  </div>
  <div class="weekNames">
    <div class="weekName" *ngFor="let day of daysOfWeek">
      {{ day.substring(0, 3).toUpperCase() }}
    </div>
  </div>
  <div class="weeks">
    <ng-container
      [ngTemplateOutlet]="weekTemplate"
      [ngTemplateOutletContext]="{ weekConsidered: firstWeek, weekNumber: 1 }"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="appointmentPanel"
      [ngTemplateOutletContext]="{ weekNumber: 1 }"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="weekTemplate"
      [ngTemplateOutletContext]="{ weekConsidered: secondWeek, weekNumber: 2 }"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="appointmentPanel"
      [ngTemplateOutletContext]="{ weekNumber: 2 }"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="weekTemplate"
      [ngTemplateOutletContext]="{ weekConsidered: thirdWeek, weekNumber: 3 }"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="appointmentPanel"
      [ngTemplateOutletContext]="{ weekNumber: 3 }"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="weekTemplate"
      [ngTemplateOutletContext]="{ weekConsidered: fourthWeek, weekNumber: 4 }"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="appointmentPanel"
      [ngTemplateOutletContext]="{ weekNumber: 4 }"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="weekTemplate"
      [ngTemplateOutletContext]="{ weekConsidered: fifthWeek, weekNumber: 5 }"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="appointmentPanel"
      [ngTemplateOutletContext]="{ weekNumber: 5 }"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="weekTemplate"
      [ngTemplateOutletContext]="{ weekConsidered: sixthWeek, weekNumber: 6 }"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="appointmentPanel"
      [ngTemplateOutletContext]="{ weekNumber: 6 }"
    ></ng-container>
  </div>
</div>

<ng-template
  #weekTemplate
  let-weekConsidered="weekConsidered"
  let-weekNumber="weekNumber"
>
  <div class="week">
    <div
      class="day"
      [class.daySelected]="
        dateClicked.weekNumber === weekNumber && dateClicked.isSelected[i]
      "
      *ngFor="let day of weekConsidered; let i = index"
      (click)="onDateClicked(weekNumber, i, day)"
      [class.disabled]="
        disableDayOfWeek(weekConsidered, weekNumber, i, day, mounth, year)
      "
    >
      <section
        [ngStyle]="
          currentYear === year && currentMounth === mounth && currentDay === day
            ? { border: '2px solid red', 'border-radius': '5p%' }
            : undefined
        "
        [class.selectedDay]="
          dateClicked.weekNumber === weekNumber && dateClicked.isSelected[i]
        "
      >
        {{ day }}
      </section>
    </div>
  </div>
</ng-template>

<ng-template #appointmentPanel let-weekNumber="weekNumber">
  <div
    class="appointments-panel"
    *ngIf="
      dateClicked.weekNumber === weekNumber &&
      dateClicked.isSelected.includes(true)
    "
  >
    <div class="appointments">
      <div class="appointmentsTitle">
        Orari disponibili per il {{ selectedDay }}
        {{ getMonthName(selectedMounth) }} {{ selectedYear }}
      </div>
      <div class="hours" *ngFor="let ele of hoursAvailable">
        <div class="hour">
          <mat-icon>access_time</mat-icon>
          <section style="margin-left: 10px">{{ ele.hour }}</section>
        </div>
        <div>
          <button
            class="button"
            *ngIf="ele.isAvailable"
            (click)="prenota(ele.hour)"
            mat-raised-button
          >
            Prenota ora!
          </button>
          <button
            class="button"
            *ngIf="!ele.isAvailable"
            mat-raised-button
            disabled
          >
            Non disponibile
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
