import { Component, ElementRef, Input, OnInit, Renderer2 } from "@angular/core";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { Location } from "@angular/common";
import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: "app-come-funziona",
  templateUrl: "./come-funziona.component.html",
  styleUrls: ["./come-funziona.component.css"],
  animations: [
    trigger("fadeInOut", [
      state(
        "hidden",
        style({
          opacity: 0,
          transform: "translateY(90%)",
        }),
      ),
      state(
        "visible",
        style({
          opacity: 1,
          transform: "translateY(0)",
        }),
      ),
      transition("hidden <=> visible", animate("300ms ease-in-out")),
    ]),
    trigger("fadeInOut2", [
      state(
        "hidden",
        style({
          opacity: 0,
        }),
      ),
      state(
        "visible",
        style({
          opacity: 1,
        }),
      ),
      transition("hidden => visible", animate("500ms ease-in")),
      transition("visible => hidden", animate("500ms ease-out")),
    ]),
    trigger("fadeInOut3", [
      state(
        "void",
        style({
          opacity: 0,
          height: "0px",
        }),
      ),
      transition("void <=> *", animate("300ms ease-in-out")),
    ]),
  ],
})
export class ComeFunzionaComponent implements OnInit {
  @Input() isVisibleTitolo = false;
  @Input() isVisibleArray = new Array(12).fill(false);

  isComeFunzionaPage: boolean = false;
  title = "Come Funziona";
  elems = [
    {
      icona: "cronometro2",
      sottotitolo: "Tempo",
      testo:
        "Un inesorabile conto alla rovescia scandirà i 60 minuti di tempo che avete a disposizione per uscire dalla stanza.",
    },
    {
      icona: "chiave_inglese",
      sottotitolo: "Cosa vi serve",
      testo:
        "Spirito di osservazione, deduzione, memoria, fantasia, ingegno... insomma materia grigia!",
    },
    {
      icona: "users",
      sottotitolo: "Partecipanti",
      testo: "Potete giocare in gruppi da 2 a 35 persone contemporaneamente.",
    },
  ];

  domandeRisposte = [
    {
      domanda: "Che cos'è un'escape room?",
      risposta:
        'Letteralmente significa "fuga dalla stanza" e prende origine dai videogiochi omonimi dove il protagonista deve risolvere degli enigmi e trovare la chiave per uscire dalla stanza.',
    },
    {
      domanda: "Si gioca con il computer?",
      risposta:
        "No. non è un gioco virtuale ma reale. Dovrete interagire con oggetti veri che toccate con mano, ad esempio: chiavi, porte, quadri e tutto ciò che si puo' trovare in una stanza.",
    },
    {
      domanda: "Servono doti particolari per fare questo gioco?",
      risposta:
        "Il gioco non richiede l’uso di agilità o di forza ed è alla portata di tutti.",
    },
    {
      domanda: "Il gioco fa paura ? E se non riusciamo a uscire?",
      risposta:
        'Il gioco non fa paura e siete seguiti durante il gioco dal "game master" che allo scadere dei 60 minuti vi aprirà la porta se non ci siete riusciti da soli.',
    },
    {
      domanda: "In quanti si gioca?",
      risposta:
        "È un gioco di gruppo dove si collabora per raggiungere un obiettivo comune. Vanno bene gruppi da due a sei partecipanti, in base alle caratteristiche dell'avventura.",
    },
    {
      domanda: "Organizzate delle sfide?",
      risposta:
        "Se siete un gruppo di amici un po' folto potete formare gruppetti e sfidarvi tra di voi, giocando di seguito un gruppo dopo l'altro. Vince chi conclude il gioco nel tempo minore oppure risolve il maggior numero di enigmi.",
    },
    {
      domanda: "Chi viene a fare questo gioco?",
      risposta:
        "Gruppi di amici, famiglie (genitori, zii, nonni, figli e nipoti) e colleghi di lavoro per fare esperienze di team building.",
    },
    {
      domanda: "Si vince qualcosa?",
      risposta:
        "L'obiettivo è completare la missione. Si vince la soddisfazione di avercela fatta. Ma non è facile!",
    },
    {
      domanda: "Cosa succede se scade il tempo?",
      risposta:
        "Dovrete fare del vostro meglio per compiere la missione che vi è stata affidata prima dello scadere altrimenti... pazienza! Bravi lo stesso.",
    },
    {
      domanda: "Un punto di forza del vostro gioco?",
      risposta:
        "Scommettiamo che alla fine dell'ora di gioco passerete un'altra ora a parlare, tra voi, di questa esperienza?",
    },
    {
      domanda: "Quando possiamo giocare?",
      risposta:
        "Alla mattina, al pomeriggio e alla sera. I giorni feriali e i festivi. Chiamate e concordate un giorno e un'ora di gioco.",
    },
    {
      domanda: "Quando siete aperti?",
      risposta:
        "Siamo aperti su appuntamento. Se passi davanti alla vetrina e suoni il campanello potrebbe non aprire nessuno ma per giocare basta telefonare o prenotare dal sito.",
    },
    {
      domanda: "Ci sono sconti di prezzo?",
      risposta:
        "Potremmo attivare ogni tanto campagne di sconti (seguici su Facebook), in caso contrario tieni presente che, al momento, siamo tra le escape room di Bologna meno care.",
    },
    {
      domanda: "Non è meglio andare al cinema?",
      risposta:
        "Scegliete voi se stare a guardare o essere i protagonisti di una nuova ed emozionante avventura.",
    },
    {
      domanda: "Ho altre domande da farvi?",
      risposta: "Siamo a tua disposizione. Chiamaci o contattaci quando vuoi.",
    },
  ];

  mostraRisposta: boolean[] = [];

  constructor(
    public location: Location,
    private el: ElementRef,
    private render: Renderer2,
    private meta: Meta,
    private titleService: Title,
  ) {}

  ngOnInit() {
    window.scrollTo(0, 0);
    if (this.location.path().includes("come-funziona")) {
      this.titleService.setTitle(
        "Come funziona - Fuga Impossibile - Bologna Escape Room",
      );
      this.meta.updateTag({
        name: "description",
        content: "Cosa è una escape room.",
      });
      this.meta.updateTag({
        name: "keywords",
        content:
          "come funziona, domande, faq, fuga impossibile, bologna, escape room, avventura, divertimento, gioco, enigmi, azione",
      });
      this.isComeFunzionaPage = true;
      this.isVisibleTitolo = true;
      this.isVisibleArray.fill(true);
      this.render.setStyle(
        this.el.nativeElement.querySelector(".container"),
        "padding",
        "120px 0 40px 0",
      );
      this.render.setStyle(
        this.el.nativeElement.querySelector(".container"),
        "background-color",
        "#1b1b1b",
      );
    }
  }

  toggleRisposta(index: number) {
    this.mostraRisposta[index] = !this.mostraRisposta[index];
  }
}
