<div class="container">
  <h2 class="title" id="come-funziona-title">Feste e Compleanni</h2>
  <div class="content">
    <img src="assets/img/feste-compleanni.webp" />
    <div class="box">
      <section class="title-box">Festeggia nella nostra escape room!</section>
      <p>
        Hai bisogno di una fantastica idea per
        <strong>festeggiare il compleanno?</strong>
      </p>
      <p>
        Oppure cerchi una festa alternativa per un
        <strong>addio al nubilato</strong>,
        <strong>una festa di laurea</strong> o
        <strong>un'addio al celibato?</strong>
      </p>
      <p>Vuoi organizzare la festa più originale a Bologna e provincia?</p>
      <p><strong>Festeggia nella nostra escape room!</strong></p>
      <p>
        C'è anche la possibilità di far giocare grandi gruppi! Per ulteriori
        informazioni contattaci al piu' presto!
      </p>
    </div>
  </div>
</div>
