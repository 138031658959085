import { Component, OnInit } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: "app-festa-e-compleanni",
  templateUrl: "./festa-e-compleanni.component.html",
  styleUrls: ["./festa-e-compleanni.component.css"],
})
export class FestaECompleanniComponent implements OnInit {
  constructor(
    private meta: Meta,
    private titleService: Title,
  ) {}
  ngOnInit() {
    this.titleService.setTitle(
      "Feste e compleanni - Fuga Impossibile - Bologna Escape Room",
    );
    this.meta.updateTag({
      name: "description",
      content: "Feste e compleanni nella escape room.",
    });
    this.meta.updateTag({
      name: "keywords",
      content:
        "festa, party, compleanno, laurea, celibato, nubilato, fuga impossibile, bologna, escape room, avventura, divertimento, gioco, enigmi, azione",
    });
  }
}
