import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { Location } from "@angular/common";
import { CalendarService } from "../../shared/services/calendar.service";

@Component({
  selector: "app-stanza",
  templateUrl: "./stanza.component.html",
  styleUrls: ["./stanza.component.css"],
  animations: [
    trigger("fadeInOut", [
      state(
        "hidden",
        style({
          opacity: 0,
        }),
      ),
      state(
        "visible",
        style({
          opacity: 1,
        }),
      ),
      transition("hidden => visible", animate("0.5s ease-in")),
      transition("visible => hidden", animate("0.3s ease-out")),
    ]),
  ],
})
export class StanzaComponent implements OnInit {
  @ViewChild("stanzaElementRef") stanzaElementRef!: ElementRef;

  @Input() isVisible = false;
  @Input() title: string = "";
  @Input() difficulty: number = 0;
  @Input() imgStanza: string = "";
  @Input() stanzaId: string = "";
  @Input() players: string = "";
  @Input() description: string = "";
  @Input() duration: string = "";
  @Input() isClicked: boolean = false;

  @Output()
  isHovered = false;
  isLeStanzaPage = false;
  isButtonVisibled = true;

  constructor(
    public calendarService: CalendarService,
    private location: Location,
  ) {}

  ngOnInit() {
    this.isButtonVisibled = true;
    if (this.location.path().includes("stanze")) {
      this.isLeStanzaPage = true;
      this.isClicked = false;
    }
    if (
      this.location.path().includes("stanze?selectedDiv") &&
      this.location.path().charAt(this.location.path().length - 1) ===
        this.stanzaId.charAt(this.stanzaId.length - 1)
    ) {
      this.isClicked = true;
    }
  }

  onHoverEnter(): void {
    this.isHovered = true;
  }

  onPrenotaClick() {
    this.isButtonVisibled = false;
    this.calendarService.mostra();
  }

  onHoverLeave(): void {
    this.isHovered = false;
  }

  scrollToComponent() {
    if (this.stanzaElementRef && this.stanzaElementRef.nativeElement) {
      this.stanzaElementRef.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }
}
