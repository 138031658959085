import { Component, HostListener, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Location } from "@angular/common";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { CalendarService } from "../../shared/services/calendar.service";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"],
  animations: [
    trigger("fadeInOut", [
      state(
        "hidden",
        style({
          opacity: 0,
          transform: "translateY(-100%)",
        }),
      ),
      state(
        "visible",
        style({
          opacity: 1,
          transform: "translateY(0)",
        }),
      ),
      transition("hidden <=> visible", animate("300ms ease-in-out")),
    ]),
  ],
})
export class NavbarComponent implements OnInit {
  url: string = "";
  lastScrollPosition = 0;
  links = [
    { label: "Home", url: "/", fragment: "" },
    { label: "Le stanze", url: "/stanze", fragment: "" },
    { label: "Come funziona", url: "/come-funziona", fragment: "" },
    { label: "Feste e Compleanni", url: "/feste-compleanni", fragment: "" },
    { label: "Team building", url: "/team-building", fragment: "" },
    { label: "Contatti", url: "/", fragment: "contattiId" },
  ];
  isClicked = new Array(8).fill(false);
  isNavbarVisible = true;
  isMenuOpen = false;
  @HostListener("window:resize", ["$event"])
  onResize(event: Event): void {
    if (window.innerWidth > 1202) {
      this.isMenuOpen = false;
      this.isNavbarVisible = true;
    }
  }

  @HostListener("window:scroll", ["$event"])
  onScroll(event: any) {
    const currentScrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    if (event.deltaY < 0) {
      this.isNavbarVisible = true;
    } else {
      if (
        currentScrollPosition > this.lastScrollPosition &&
        currentScrollPosition > 100
      ) {
        this.isNavbarVisible = false;
      } else {
        this.isNavbarVisible = true;
      }
    }

    this.lastScrollPosition = currentScrollPosition;
  }
  constructor(
    private router: Router,
    private location: Location,
  ) {
    this.url = this.location.path();
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isClicked = new Array(8).fill(false);
        if (event.url.includes("stanze")) {
          this.isClicked[1] = true;
        } else if (event.url.includes("come-funziona")) {
          this.isClicked[2] = true;
        } else if (event.url.includes("feste-compleanni")) {
          this.isClicked[3] = true;
        } else if (event.url.includes("team-building")) {
          this.isClicked[4] = true;
        } else {
          this.isClicked[0] = true;
        }
      }
    });
  }

  onPrenotaClick() {
    this.router.navigateByUrl("/stanze").then();
  }

  onLoginClick() {
    this.router.navigateByUrl("/login").then();
  }
}
