import { Component, OnInit } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: "app-team-building",
  templateUrl: "./team-building.component.html",
  styleUrls: ["./team-building.component.css"],
})
export class TeamBuildingComponent implements OnInit {
  constructor(
    private meta: Meta,
    private titleService: Title,
  ) {}

  ngOnInit() {
    this.titleService.setTitle(
      "Team building - Fuga Impossibile - Bologna Escape Room",
    );
    this.meta.updateTag({
      name: "description",
      content: "Team building nella escape room.",
    });
    this.meta.updateTag({
      name: "keywords",
      content:
        "team building, colleghi, lavoro, fuga impossibile, bologna, escape room, avventura, divertimento, gioco, enigmi, azione",
    });
  }
}
