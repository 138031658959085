import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from "./components/home/home.component";
import { StanzeComponent } from "./components/stanze/stanze.component";
import { ComeFunzionaComponent } from "./components/come-funziona/come-funziona.component";
import { FestaECompleanniComponent } from "./components/festa-e-compleanni/festa-e-compleanni.component";
import { TeamBuildingComponent } from "./components/team-building/team-building.component";
import { LoginComponent } from "./components/login/login.component";

const APP_ROUTES: Routes = [
  { path: "", component: HomeComponent },
  //{ path: "login", component: LoginComponent },
  { path: "stanze", component: StanzeComponent },
  { path: "come-funziona", component: ComeFunzionaComponent },
  { path: "feste-compleanni", component: FestaECompleanniComponent },
  { path: "team-building", component: TeamBuildingComponent },
  { path: "**", redirectTo: "" },
];
@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(APP_ROUTES, {
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
