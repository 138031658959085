<div class="container">
  <h2>Contatti</h2>
  <div style="display: flex; flex-wrap: wrap">
  <div class="contatti">
    <div>
      <i class="icon-mail"></i>
      <div>
        <section><a href="mailto:bologna@fugaimpossibile.it">bologna@fugaimpossibile.it</a></section>
        <section><a href="mailto:blitzaps@pec.it">blitzaps@pec.it</a></section>
        <section><a href="mailto:blitzaps@gmail.com">blitzaps@gmail.com</a></section>
      </div>
    </div>
    <div>
      <i class="icon-telefono"></i>
      <div>
        <section>
          <a style="" href="tel:+393478932984">+39 347 89 32 984</a>
        </section>
      </div>
    </div>
    <div>
      <i class="fa fa-facebook-square" aria-hidden="true"></i>
      <div>
        <section>
          <a style="" href="https://www.facebook.com/FugaImpossibile/">FugaImpossibile</a>
        </section>
      </div>
    </div>
    <div>
      <i class="fa fa-instagram" aria-hidden="true"></i>
    <div>
        <section>
          <a style="" href="https://www.instagram.com/explore/locations/1701650593381305/fuga-impossibile-bologna---escaperoom/">fuga-impossibile-bologna---escaperoom</a>
        </section>
      </div>
    </div>
    <div>
      <i class="icon-indirizzo"></i>
      <div>
        <section>Via della Grada, 8/a-b-c</section>
        <section>40122 Bologna</section>
      </div>
    </div>
  </div>
    <div class="map-container">
      <iframe
            class="map"
            allowfullscreen
            frameborder="0"

            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11383.496844872978!2d11.3290377!3d44.4972442!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3b817425bc0b4b5c!2sFuga+Impossibile+escape+room!5e0!3m2!1sit!2sit!4v1538052361753&style=element:geometry%7Ccolor:0x212121&style=element:labels.icon%7Cvisibility:off&style=element:labels.text.fill%7Ccolor:0x757575&style=element:labels.text.stroke%7Ccolor:0x212121&style=feature:administrative%7Celement:geometry%7Ccolor:0x757575&style=feature:administrative.country%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:administrative.locality%7Celement:labels.text.fill%7Ccolor:0xbdbdbd&style=feature:poi%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:poi.park%7Celement:geometry%7Ccolor:0x181818&style=feature:poi.park%7Celement:labels.text.fill%7Ccolor:0x616161&style=feature:poi.park%7Celement:labels.text.stroke%7Ccolor:0x1b1b1b&style=feature:road%7Celement:geometry.fill%7Ccolor:0x2c2c2c&style=feature:road%7Celement:labels.text.fill%7Ccolor:0x8a8a8a&style=feature:road.arterial%7Celement:geometry%7Ccolor:0x373737&style=feature:road.highway%7Celement:geometry%7Ccolor:0x3c3c3c&style=feature:road.highway.controlled_access%7Celement:geometry%7Ccolor:0x4e4e4e&style=feature:road.local%7Celement:labels.text.fill%7Ccolor:0x616161&style=feature:transit%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:water%7Celement:geometry%7Ccolor:0x000000&style=feature:water%7Celement:labels.text.fill%7Ccolor:0x3d3d3d&size=480x360"
      ></iframe>
    </div>
</div>
</div>