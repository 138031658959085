<div class="container" xmlns="http://www.w3.org/1999/html">
  <h2
    class="title"
    id="come-funziona-title"
    [@fadeInOut]="isVisibleTitolo ? 'visible' : 'hidden'"
  >
    {{ title }}
  </h2>
  <div class="summary" *ngIf="isComeFunzionaPage">
    <p>
      Chiusi in una stanza dovete cercare di uscire grazie ad alcuni indizi,
      risolvendo enigmi, affrontando giochi di manipolazione e giochi di logica.
    </p>
    <p>
      Suspense, avventura ed emozioni vi aspettano, ma sbrigatevi: avete solo 60
      minuti e il tempo non è il vostro unico nemico.
    </p>
  </div>
  <div class="content">
    <div class="box" *ngFor="let elem of elems; let i = index">
      <div
        class="widget"
        id="icona{{ i }}"
        style="position: relative"
        [@fadeInOut]="isVisibleArray[i + 3] ? 'visible' : 'hidden'"
      >
        <div class="barra-diagonale"></div>
        <div class="sfondo"></div>
        <div class="image">
          <i class="icon-{{ elem.icona }}"></i>
        </div>
      </div>
      <h2
        class="sottotitolo"
        id="sottotitolo{{ i }}"
        [@fadeInOut]="isVisibleArray[i + 3] ? 'visible' : 'hidden'"
      >
        {{ elem.sottotitolo }}
      </h2>
      <p
        class="testo"
        id="testo{{ i }}"
        [@fadeInOut]="isVisibleArray[i + 3] ? 'visible' : 'hidden'"
      >
        {{ elem.testo }}
      </p>
    </div>
  </div>
  <div class="faq" *ngIf="isComeFunzionaPage">
    <div class="faq-title">FAQ</div>
    <div
      *ngFor="let elem of domandeRisposte; let i = index"
      style="padding: 10px 0 10px 0"
    >
      <div class="question">
        <button
          mat-mini-fab
          class="question-button"
          (click)="toggleRisposta(i)"
        >
          <i
            [class]="mostraRisposta[i] ? 'fa fa-minus' : 'fa fa-plus'"
            aria-hidden="true"
          ></i>
        </button>
        <section>{{ elem.domanda }}</section>
      </div>
      <div class="answer" *ngIf="mostraRisposta[i]" [@fadeInOut3]="">
        {{ elem.risposta }}
      </div>
    </div>
  </div>
  <iframe
    class="youtube-video"
    *ngIf="isComeFunzionaPage"
    allowfullscreen=""
    frameborder="0"
    src="https://www.youtube.com/embed/Hmkf5qEr5pU?rel=0"
  ></iframe>
</div>
